import { inputZipChecker, refreshScheduleData } from "../../util";
import { $ } from "../../third-party-imports";
export function fspPreFilledData(nextId:any){
    $('.personal-info-form  input[name="inputFirstName"]').val('');
    $('.personal-info-form  input[name="inputLastName"]').val('');
    $('.personal-info-form  input[name="email"]').val('');
    $('.personal-info-form  input[name="inputPhnNumber"]').val('');
    $('.personal-info-form  input[name="inputAddress"]').val('');
    $('.personal-info-form  input[name="apartment"]').val('');
    $('.personal-info-form  input[name="inputcity"]').val('');
    $(".js-next-btn").removeClass("disble-btn").prop("disabled", false);
    inputZipChecker();
    if(!window.objOSGlobal.requestBodyValues.IsLeadOnly){
        nextId = $('.service-search-screen').next().attr("id");
        $(".tab-pane.fieldset").removeClass("active");
        refreshScheduleData(new Date().getFullYear(), new Date().getMonth() + 1);
        $('[href="#' + nextId + '"]').tab('show'); 
        $('#'+nextId).addClass('active');
        $('[href="#step2"]').closest('li').show();
        $('.scheduler-slide .progress').css('display','flex');
        $('.nav-wizard.progressbar').css('display','flex');
        $(".modal-header-one").addClass("d-none");
        $('.modal-header-two').css('display', 'flex');
        $(".selected-date-txt").empty();
        let postalCode = localStorage.getItem('zipcode');
        if (postalCode) {
          $('.personal-info-form input[name="inputZipCode"]').val(postalCode);
        }
        if( window.innerWidth <=414 ) {
          $('.scheduler-slide .progress').css( "maxWidth", '270px');
        }
    }else {
        fspLeadFlow();
    }
}
export function fspLeadFlow(){
    //Logic for non caldedar slow start from here
    $('.tab-pane.fieldset').removeClass('active');
    $('[href="#step2"]').closest('li').hide();
    $(".modal-header-one").addClass("d-none");
    $('.modal-header-two').css('display', 'flex');
    $('.progressbar li').css('width', 'calc(100%/2)');
    if( window.innerWidth <=414 ){
      $('.scheduler-slide .progress').css( "maxWidth", '180px');
    }else {
      $('.scheduler-slide .progress').css( "maxWidth", '333px');
    }
    $('.contact-information-screen').addClass('active');
    $('.progress-bar').css({ width:'5.2%' });
    $('.scheduler-slide .progress').css('display','flex');
    $('.nav-wizard.progressbar').css('display','flex');
    let postalCode = localStorage.getItem('zipcode');
    $('.personal-info-form input[name="inputZipCode"]').val(postalCode);
    $('.scheduler-modal .modal-content').removeClass('banner-wizard');
}