import { initBlogArticlePage } from "./initBlogArticlePage";
import { $ } from "../third-party-imports"
import { blogContactform } from "./blog-contact-form";
import { copyToClipboard, getBrandDetailsFromBrandJson } from "../util/util";

$(function ()
{
    let domainType = window.DEFINE_VALUE.Domain_Type;
    var concepId = $('#conceptId').val();
    let country = $("#countryCode").val();
    let brandName = $('#brandName').val();

    let pagetype = $('#pageType').val();
    if (domainType == "digital")
    {
        if (pagetype == 'blog')
        {
            blogContactform();

            initBlogArticlePage();

            $('.return-blog a').click(async function (eve:any)
            {
                eve.preventDefault();
                let linkHref = window.location.href.toLowerCase();
                var returnlink = localStorage.getItem('current_context_url');
                if (returnlink === "" || returnlink === null)
                {
                    let localDBA = "none";
                    if (window.g2Data)
                    {
                        localDBA = window.g2Data.responseHeader.params.fq[0].toLowerCase().replace("custom_s_local_dba:", "").replace("(", "").replace(" or none)", "");

                    }
                    else
                    {
                        localDBA = $("#weblocationId").length ? linkHref.split('/')[3] : "none";
                    }                    
                    const result = await getBrandDetailsFromBrandJson();
                    if (result.length > 0)
                    {

                        let brandJsonObj = result.find((item: any) => item.id === concepId);
                        let brandblogURLName: any;
                        let brandlocalblogURLName: any;
                        if (brandName == 'my-handyman')
                        {
                            brandblogURLName = brandJsonObj ? brandJsonObj.AlternateBrandList[0].alternate_brand_blog_url_name : "";
                            brandlocalblogURLName = brandJsonObj ? brandJsonObj.AlternateBrandList[0].alternate_brand_local_blog_url_name : "";
                        } else
                        {
                            brandblogURLName = brandJsonObj ? brandJsonObj.brand_blog_url_name : "";
                            brandlocalblogURLName = brandJsonObj ? brandJsonObj.brand_local_blog_url_name : "";
                        }

                        if (localDBA != "none")
                        {
                            window.location.href = encodeURI(`${window.location.origin}/${localDBA}/${brandlocalblogURLName}`);
                        }
                        else
                        {
                            window.location.href = encodeURI(`${window.location.origin}/${brandblogURLName}`);
                        }
                    }
                } else
                {
                    window.location.replace(returnlink);
                }

            });
        }
    }
});

// fix for 139981
$("#a_share_link_blogs").on("click", function (e: any)
{
    e.preventDefault();
    let pageUrl = decodeURIComponent(window.location.href);
    let urlImg = pageUrl;
    copyToClipboard(urlImg);
});


