import {$} from "../third-party-imports";
declare global {
    interface Window {
        LiveChatWidget: any;
    }
  }

  window.addEventListener("load", function() {
    $("#chat-now-btn").click (function(e:any) {
        e.preventDefault();
        window.LiveChatWidget.call("maximize");
    })
  });

