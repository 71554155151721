export const Api_Error_Msg = `Due to a system error, we did not get your request. Please call us for immediate assistance. `
export const sitekey = `0x4AAAAAAAKzI--CQZv3qZSM`;
export const SECRET_KEY = `0x4AAAAAAAKzIxyzPC_g9B3K4lz6dwlnFuk`;   
export const captchaErrorMsg = `Sorry, there was an error processing your request. Please call us for immediate assistance.`;
export const applyLocalLinkHeaderArray: string[] = [
    `.desktop-header .nav-container .find-row .top-list a[aria-labelledby="Apply Locally"]`,
    `.desktop-header .nav-container .mobile-search .top-list a[aria-labelledby="Apply Locally"]`,
    `.desktop-header .nav-container .nav-section .dropdown-menu a.dropdown-item[aria-labelledby="Apply Locally"]`,
    `.mobile-header .nav-container .find-row .top-list a[aria-labelledby="Apply Locally"]`,
    `.mobile-header .nav-container .mobile-search .top-list a[aria-labelledby="Apply Locally"]`,
    `.mobile-header .nav-container a.dropdown-item[aria-labelledby="Apply Locally"]`,
    `footer .footer .footer-top-bg .list-unstyled a[aria-labelledby = "Apply Locally"]`,
];
