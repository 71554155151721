import { $ } from "../third-party-imports";
import { apiConfig } from "../config/apiConfig";
import {animation} from './refreshScheduleData';
import {digitalDataScript} from './digitalDataScript';
import {footerSocialLinks} from './footerSocialLinks';
import { hidingMttMenuLink } from "./hidingMttMenuLink";
import { hidingPhgMenuLink } from "./hidingPhgMenuLink";
import { htmlInjectionField } from "./html-injection-field";
import { Api_Error_Msg } from "../config/constant";
import { getFullAttributeApi } from "./getFullAttributeApi";
import { gtmCustomEventLeadFlow } from "./gtm-custom-event-lead-flow";
import { mrhHeaderDynamicLocalMenu } from "../mrh-dynamic-menu";
import {headerDynamicLocalMenu as headerLocalMenuGenrate} from '../dynamic-menu';
import { apiCaching } from "../config/cachingConfig";
import { getCachedAPIResponse } from "../apiCaching/apiWrapper";
import {applyLocalLinkHeaderArray} from '../config/constant';
export function getApplianceProblemList(serviceSkuId:any){
    var conceptid = 8;
	//var serviceTypeProblemAggregates = "https://api.nblytest.com/serviceorchestrations-api-develop/v1/public/concepts/";
    $.ajax({
        url: apiConfig.serviceTypeProblemAggregates + conceptid + '/service-type-problem-aggregates/search?&apikey=' + window.DEFINE_VALUE.JS_API_KEY,
        dataType: 'json',
        type: 'GET',
        success: function (data){
            var MRAproblemlist = data;
            problemListAppend(MRAproblemlist,serviceSkuId);
        }, error: function (e:any){
            console.log(e.message);
        }
    });
}
function problemListAppend(MRAproblemlist:any,serviceSkuId:any){
    var filteredProblemList = MRAproblemlist.filter((problem:any) => problem.serviceSkuId == serviceSkuId);
    // console.log("filter Problem List",filteredProblemList);
    var applianceList:any = [];
    var rows = [];
    var rowsWrap:any = [];
    var resultArray = filteredProblemList;
    $.each(resultArray, function (index:any, result:any){
        applianceList.push('<div class="appliance-col"><div class="custom-checkbox"><input class="prolemsCheckBox" value="'+result.serviceTypeProblemName+'" id="appliance'+index+'" type="checkbox"> <label for="appliance'+index+'">'+result.serviceTypeProblemName+'</label> </div> </div>');
    });
    for(let i=0;i<applianceList.length/4;i++){
        var startingIndex = i*4;
        rows.push(applianceList.slice(startingIndex,(startingIndex+4)));
    }
    for(let i=0;i<rows.length;i++){
    rowsWrap.push(generateProblemListRow(rows[i]))
    }
    $('.appliance-problem-list-wrap').empty();
    $('.appliance-problem-list-wrap').append(rowsWrap);
}
function generateProblemListRow(listArray:any){
	return '<div class="appliance-row">'+listArray.join('')+'</div>'
}
$(function(){
    var sectionCount  = $('#main').children('section').not('.primary-hero-banner, .blog-banner-wrapper').length;
    if($('.service-coupon-section').length>0){
        couponExpired();
    }
    var countDnone = $("#main").find('.service-coupon-section.d-none').length;
    sectionCount = countDnone ? sectionCount - countDnone :sectionCount;
    if($('.global-pagination').length > 0){
        if(sectionCount>16){
            $('.global-pagination').removeClass('d-none')
            customPagination(sectionCount);
        }else{
            $('.global-pagination').addClass('d-none');
        }
    }
})

function customPagination(sectionCount:any){
    let pageSize = 16;
    let curPage = 0;
    let pageCount =  sectionCount / pageSize;
    let currentIndex:any;
    let item = [];
    let separatorAdded:Boolean = false;
    let new_page:any;
    for(let i = 1; i<=pageCount;i++){
        item.push(i);
    }
    $('.current_page').val(1);
    $('.show_per_page').val(pageCount);
    render();
    let showPage = function(page:any) {
        $("#main section").not('.primary-hero-banner, .blog-banner-wrapper').hide();
        $("#main section").not('.primary-hero-banner, .blog-banner-wrapper, .d-none').each(function(this:any,n:any) {
            if (n >= pageSize * (page - 1) && n < pageSize * page)
                $(this).show();
        });        
    }
    showPage(1);
    function isPageInRange( curPage:any, index:any, maxPages:any, pageBefore:any, pageAfter:any ) {
            if (index <= 1) {
                // first 2 pages
                return true;
            }
            if (index >= maxPages - 2) {
                // last 2 pages
                return true;
            }
            if (index >= curPage - pageBefore && index <= curPage + pageAfter) {
                return true;
            }
    }
    function render(){
        $(".global-pagination .page-numbers").empty();
        for(let i = 0 ; i<pageCount;i++){
                if ( isPageInRange( curPage, i, pageCount, 2, 2 ) ) {
                    $(".global-pagination .page-numbers").append('<li class="page-item"><a href="#" class="pagin-link pagination-item text-sm"  data-page='+(i+1)+'>'+(i+1)+'</a></li> ');
                    separatorAdded = false;
                }else{
                    if (!separatorAdded) {
                        $(".global-pagination .page-numbers").append('<li class="separator" />');
                        separatorAdded = true;
                    }    
                }
        }
        if(currentIndex == 1){
            $('.global-pagination .prev').addClass('disabled');
            $('.global-pagination .first').addClass('disabled');
        }
        if(currentIndex == Math.ceil(pageCount)){
            $('.global-pagination .next').addClass('disabled');
            $('.global-pagination .last').addClass('disabled');
        }
        $(".global-pagination .page-numbers li a.pagin-link").click(function(this:any, e:any) {
            e.preventDefault();
            $(".global-pagination .page-numbers li a.pagin-link").removeClass("current");
            $('.global-pagination .prev').removeClass('disabled');
            $('.global-pagination .next').removeClass('disabled');
            $('.global-pagination .first').removeClass('disabled');
            $('.global-pagination .last').removeClass('disabled');
            currentIndex = $(this).attr('data-page');
            $('.current_page').val(currentIndex);
            curPage = parseInt(currentIndex);
            render();
            showPage(parseInt(currentIndex));
            $('.global-pagination a[data-page='+curPage+']').addClass("current");
            scrollUp();
        });
    }
    $('.global-pagination .pagination li a.next').click(function(e:any){
        e.preventDefault();
        new_page = parseInt($('.current_page').val() as any) + 1;
        var lastIndex = Math.ceil(pageCount);
        curPage = parseInt(new_page);
        render();
        if(new_page !=lastIndex + 1){
            $('.global-pagination .next').removeClass('disabled');
            $('.current_page').val(new_page);
            $(".global-pagination .page-numbers li a").removeClass("current");
            $('.global-pagination .prev').removeClass('disabled');
            $('.global-pagination a[data-page='+new_page+']').addClass("current");
            $('.global-pagination .first').removeClass('disabled');
            $('.global-pagination .last').removeClass('disabled');
            showPage(new_page);
        }
        if(new_page == lastIndex){
            $('.global-pagination .next').addClass('disabled');
            $('.global-pagination .last').addClass('disabled');
        }
        scrollUp();
    });
    $('.global-pagination .pagination li a.prev').click(function(e){
        e.preventDefault();
        new_page = parseInt($('.current_page').val() as any) - 1;
        curPage = parseInt(new_page);
        render();
        if(new_page!=0){
            $('.global-pagination .next').removeClass('disabled');
            $('.global-pagination .prev').removeClass('disabled');
            $('.global-pagination .first').removeClass('disabled');
            $('.global-pagination .last').removeClass('disabled');
            $('.current_page').val(new_page);
            $(".global-pagination .page-numbers li a").removeClass("current");
            $('.global-pagination a[data-page='+new_page+']').addClass("current");
            showPage(new_page);
        }
        if(new_page ==1){
            $('.global-pagination .prev').addClass('disabled');
            $('.global-pagination .first').addClass('disabled');
        }
        scrollUp();
        });
    $('.global-pagination .pagination li a.first').click(function(e){
        e.preventDefault();
        $(".global-pagination .page-numbers li a").removeClass("current");
        $('.global-pagination a[data-page="1"]').addClass("current");
        $('.global-pagination .next').removeClass('disabled');
        $('.global-pagination .last').removeClass('disabled');
        $('.global-pagination .prev').addClass('disabled');
        $('.global-pagination .first').addClass('disabled');
        $('.current_page').val(1);
        showPage(1);
        scrollUp();
    });
    $('.global-pagination .pagination li a.last').click(function(e){
        e.preventDefault();
        var lastPage = Math.ceil(pageCount);
        $(".global-pagination .page-numbers li a").removeClass("current");
        $('.global-pagination a[data-page='+lastPage+']').addClass("current");
        $('.global-pagination .next').addClass('disabled');
        $('.global-pagination .last').addClass('disabled');
        $('.global-pagination .prev').removeClass('disabled');
        $('.global-pagination .first').removeClass('disabled');
        $('.current_page').val(lastPage);
        showPage(lastPage);
        scrollUp(); 
    });

    function scrollUp(){
            $('html, body').animate({
                scrollTop: $("#main").offset().top
            }, 500);
        }
    $('.global-pagination .page-numbers li:nth-child(1) a').addClass('current');
}

function couponExpired(){
    let d = new Date();
    let month = d.getMonth()+1;
    let day = d.getDate();
    let currentDate =  month + '/' +
        (month<10 ? '0' : '') + day + '/' +
        (day<10 ? '0' : '') +  d.getFullYear();
    $('.service-coupon-section').each(function(i, obj) {
        let expireDatesValue =  $(this).find('.expires-date').text();
        let couponDate = expireDatesValue.split(":")[1];
        if(new Date(couponDate) > new Date(currentDate)){
            $(this).removeClass('d-none');
        }else{
            $(this).addClass('d-none');
        }
    });
}

$('.media-app-btn').on('click',function(evt){
    evt.preventDefault();
    let phoneNumber = $('.media-app-form input[name="inputPhnNumber"]').val();
    phoneNumber = phoneNumber.toString().replace(/[- )(]/g, '');
    let url = $('.media-app-form input[name="opsystem"]:checked').siblings('input[type="hidden"]').val();
    if(url){
        url=url;
    }else{
        url = $('.media-app-form .fallback-link').val();
    }
    if(phoneNumber){
        var payload = {
            "UserPhone": phoneNumber,
            "MobileAppPromoDownloadUrl":url
        }
        $(".media-app-form .media-app-btn").addClass("disble-btn").prop("disabled", true);
        $('.media-app-form input[name="inputPhnNumber"]').val('');
        mediaAppMobileNotification(payload);
    }
})

function mediaAppMobileNotification(payload:any){
    $.ajax({
        url:apiConfig.mobileAppPromoNotification +'?apikey='+window.DEFINE_VALUE.JS_API_KEY,          
        type: 'POST',        
        dataType: 'json',        
        data: JSON.stringify(payload),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        success: function (data){
            $(".with-input-field").hide();
            $(".msg-sent").removeClass("d-none");
        }, error: function (e:any){
            animation.modalFlag = true;
            if($("#brandName").val() === 'mr-handyman' || $("#brandName").val() === 'my-handyman'){
            $(".modal-header-one").addClass("d-none");
            }
            $("#js-schedulerDialog").modal('show');
            $('#schedulerWizard .col-md-12').eq(2).hide();
            $("#schedulerWizard").append(`<h2 id = "headermsg" class = "custom-h2 moadal-h2-text primary-theme-clr">${Api_Error_Msg}</h2>`)
            $("#schedulerWizard").after('<div class="close-location align-center-wrap" id = "closebtnwraper"><input type="button" value="Close" id = "modal-btn-close" class="js-next-btn primary-btn next backToServiceBtn modal-leadbtn-close custom-cls-btn" data-dismiss="modal" aria-label="Close"></div>');
            $('.modal').on('hidden.bs.modal', function () {
                if(animation.modalFlag){
                 location.reload();
                }
            });
            // console.log(e.message);
        }
    });

let scroll_link = $('.js-scroll-section');
scroll_link.click(function (e){
e.preventDefault();
let url = $('body').find($(this).attr('href')).offset().top;
$('html, body').animate({
scrollTop: url
}, 700);
$(this).parent().addClass('active');
$(this).parent().siblings().removeClass('active');
return false;
});

}

$(function (){
    if($('#weblocationId').length) {
        var weblocation = $('#weblocationId').val();
        localStorage.setItem("weblocationId", weblocation.toString());
        hidingMttMenuLink();
        hidingPhgMenuLink();
    } //Bug 100211
    // googleMapsAPIload();
    localiseNationalPage();
    digitalDataScript();
    footerSocialLinks();
    htmlInjectionField();
    
    $('.sitemapPageNav a').on('click', function(e:any) {
        e.preventDefault();
        var targetId = $(this).attr('href');
        var target = $(targetId);
        if (target.length) {
            $('html, body').animate({
                scrollTop: target.offset().top - (targetId == '#pages' ?155:125)
            }, 700); // Adjust the duration as needed
        }
        window.location.hash = targetId
    });

})

$(document).on("mouseover", '.header-classic .nav-section ul.navbar-nav li.js-dropdown a.dropdown-toggle', function(){
    $('.mega-menu-links').removeClass('adjust-nav-height');
});
$(document).on("click", '.header-classic .navbar-nav .dropmenu ul.mega-menu-front li.list-item', function(){
    $('.mega-menu-links').addClass('adjust-nav-height');
});

$(document).on("click", '.mega-menu-links .js-mega-menu-cont .desktop-item ul li', function(){
    $('.mega-menu-links').removeClass('adjust-nav-height');
});


$('.address-bumper-screen .back.js-previous-btn').on('click',function(e:any){
    e.preventDefault();
    $(".tab-pane.fieldset").removeClass("active");
    $(".zip-entry-screen").addClass('active');
});

export function savePersonalInfoToSession() {
    const firstnameobj = $('.personal-info-form').find('input[name="inputFirstName"]').val();
    const lastnameobj = $('.personal-info-form').find('input[name="inputLastName"]').val(); 
    const emailobj = $('.personal-info-form').find('input[name="email"]').val();
    const mobileNumberobj = $('.personal-info-form').find('input[name="inputPhnNumber"]').val();
    const address = $('.mly-service-contact-form-screen input[name="inputAddress"]').val();
    const address2 = $('.mly-service-contact-form-screen input[name="inputAddress2"]').val();
    const city = $('.mly-service-contact-form-screen input[name="inputcity"]').val();
    const postalcode = localStorage.getItem('zipcode');    
    const estSqft = $('#inputsquarefeet').val();
    const estBedRoom = $('#inputbedrooms option:selected').val();
    const estBaathRoom = $('#inputbathrooms option:selected').val();
    const estState:any =     $('select[name="inputstate"] option:selected').val();
    sessionStorage.setItem("estFirstName",firstnameobj);
    sessionStorage.setItem("estLastName",lastnameobj);
    sessionStorage.setItem("estEmail",emailobj);
    sessionStorage.setItem("estPhone",mobileNumberobj);
    sessionStorage.setItem("estAddress",address);
    sessionStorage.setItem("estAddress2",address2);
    sessionStorage.setItem("estCity",city);
    sessionStorage.setItem("estPostalCode",postalcode);
    sessionStorage.setItem("estSqft",estSqft)
    sessionStorage.setItem("estBedRoom",estBedRoom);
    sessionStorage.setItem("estBaathRoom",estBaathRoom);
    sessionStorage.setItem("estState",estState);
}

export function saveContactInfoToSession() {
    const firstnameobj = $('#ContactForm').find('input[name="inputFname"]').val();
    const lastnameobj = $('#ContactForm').find('input[name="inputLname"]').val(); 
    const emailobj = $('#ContactForm').find('input[name="inputEmail"]').val();
    const mobileNumberobj = $('#ContactForm').find('input[name="inputPhone"]').val();
    const zipcode = $('#ContactForm').find('input[name="inputZip"]').val();

    sessionStorage.setItem("estFirstName",firstnameobj);
    sessionStorage.setItem("estLastName",lastnameobj);
    sessionStorage.setItem("estEmail",emailobj);
    sessionStorage.setItem("estPhone",mobileNumberobj);
    sessionStorage.setItem("estAddress",'');
    sessionStorage.setItem("estAddress2",'');
    sessionStorage.setItem("estCity",'');
    sessionStorage.setItem("estState",'');
    sessionStorage.setItem("estPostalCode",zipcode);
}
//logic for handling Localization on National page from PPC/Ad campaign for Analytics start from here

const localiseNationalPage = () => {
    const url = new URL(window.location.href);
    const localizationId = sessionStorage.getItem("localizationId");
    if (url.search.indexOf("location=") > -1) {
        const index = url.search.indexOf("location=");
        const WeblocationId = url.search.slice(index).split("=")[1];
        if(WeblocationId){
            window.objOSGlobal.requestBodyValues.WebLocationId = WeblocationId;
        }else{
            window.objOSGlobal.requestBodyValues.WebLocationId = localizationId ? localizationId : '';
        }
        if (!$("#local_weblocationId")[0] && !$("#full_dbaName")[0]) {
            if(window.objOSGlobal.requestBodyValues.WebLocationId !==''){
                sessionStorage.setItem("localizationId",window.objOSGlobal.requestBodyValues.WebLocationId);
                localizationForCompaign();
            }
        }
    }else{
        if(localizationId){
            window.objOSGlobal.requestBodyValues.WebLocationId = localizationId;
            localizationForCompaign();
        }
    }
};  

const localiseHeaderFooter = () =>{
  if($("#brandName").val() !== 'mr-handyman' && $("#brandName").val() !== "my-handyman") {
    headerLocalMenuGenrate();
  }else {
    mrhHeaderDynamicLocalMenu();
  }
}

function localizationForCompaign(){
    getFullAttributeApi(
        window.objOSGlobal.requestBodyValues.WebLocationId,
        () => {
            const local_url = window.objOSGlobal.requestBodyValues.locationWebsiteUrl;
            const url = new URL(local_url);
            const path_name = url?.pathname;
            const referrer = new URL(window.location.href).origin + path_name;
            $('header#site-header').load('' + referrer + ' header');
            $('footer').load('' + referrer + ' footer');
            let leadObj:any;
            const webLocationId = `<input type="hidden" value= "${window.objOSGlobal.requestBodyValues.WebLocationId}" id="local_weblocationId">`;
            const dbaname = `<input type="hidden" value="${window.objOSGlobal.paramsUI.doingBusinessAs}" id="full_dbaName">`;
            
            $("body").prepend(webLocationId);
            $("body").prepend(dbaname);
            $('.banner-txt-sec.js-banner-spacing').find('.or-call').remove();
            $('.banner-txt-sec.js-banner-spacing').append(`<a href="tel:${window.objOSGlobal.paramsUI.brandPhoneNumber}" class="or-call">or call <b>${window.objOSGlobal.paramsUI.brandPhoneNumber} </b></a>`);
            if ($("#a_servicepage_bannerwithcta_locationphone").length > 0) {
            $('#a_servicepage_bannerwithcta_locationphone').get(0).lastChild.nodeValue = window.objOSGlobal.paramsUI.brandPhoneNumber;
            $('#a_servicepage_bannerwithcta_locationphone').attr('href', 'tel:' + window.objOSGlobal.paramsUI.brandPhoneNumber);
            }
            if($("#a_servicepage_fullwidthcta_locationphone").length > 0) {
            $('#a_servicepage_fullwidthcta_locationphone').get(0).lastChild.nodeValue = window.objOSGlobal.paramsUI.brandPhoneNumber;
            $('#a_servicepage_fullwidthcta_locationphone').attr('href', 'tel:' + window.objOSGlobal.paramsUI.brandPhoneNumber);
            }
            leadObj = {
                event:'custEv_LocalDataLayerOnLoad',
                locationID: window.objOSGlobal.requestBodyValues.WebLocationId,
                locationDBA: window.objOSGlobal.paramsUI.doingBusinessAs
            }
            gtmCustomEventLeadFlow(leadObj);
            localiseHeaderFooter();
        }
    );
}
export function add_custEv_LocalDataLayer() {
    const locationWebLocationID =  window.objOSGlobal?.paramsUI?.localfranchiseWebLocationId;
    const full_Dba = window.objOSGlobal?.paramsUI?.doingBusinessAs;
    const leadObj = {
        event: 'custEv_LocalDataLayerOnLoad',
        locationID: locationWebLocationID,
        locationDBA: full_Dba
    }
    $('#local_weblocationId').remove();
    $('#full_dbaName').remove();
    if (!$('#local_weblocationId')[0] && !$('#full_dbaName')[0]) {
        const webLocationId = `<input type="hidden" value= "${locationWebLocationID}" id="local_weblocationId">`;
        const dbaname = `<input type="hidden" value="${full_Dba}" id="full_dbaName">`
    
        $('body').prepend(webLocationId);
        $('body').prepend(dbaname);
        gtmCustomEventLeadFlow(leadObj);
    }
}
export function getLocalization(): string
{
    if (localStorage.dbaname)
    {
        return "Local";
    }
    else
    {
        return "National";
    }
}

// 277293: Add Page Type Data layer Start
export async function getGTMPageData(country:string){
    // if (window.brandData && window.brandData.add_pagetype_datalayer)
    /*
    const brands = `${window.location.origin}/${country}/en-${country}/_assets/brands.json`;
    $.getJSON(brands).then(function (result: any)
    {
        if (result.length > 0)
        {
            const items = $.grep(result, function (e: any) { return e.code === $('#conceptCode').val() });
            const flag=items[0].add_pagetype_datalayer;
            if (flag)
            {
                const pageType = getPageType();
                const pageLocalization = getLocalization();
                let pageData = {
                    event: 'pageDataLayer',
                    pageType,
                    localization: pageLocalization
                }
                dataLayer.unshift(pageData);
            }
        }

    });
    */
    const result = await getBrandDetailsFromBrandJson();
    if (result.length > 0)
        {
            const items = $.grep(result, function (e: any) { return e.code === $('#conceptCode').val() });
            const flag=items[0].add_pagetype_datalayer;
            if (flag)
            {
                const pageType = getPageType();
                const pageLocalization = getLocalization();
                let pageData = {
                    event: 'pageDataLayer',
                    pageType,
                    localization: pageLocalization
                }
                dataLayer.unshift(pageData);
            }
        }
}
function getPageType(): string
{
    let pageTypeObj = {}
    let originUrl = window.location.origin;
    let indexUrl = window.location.href;
    if (originUrl === indexUrl.substring(0, indexUrl.length - 1) || indexUrl === `${originUrl}/${localStorage.dbaname}/`)
    {
        return "Homepage"
    }
    if (window.brandData?.gtm_pageType_pages?.service && window.brandData?.gtm_pageType_pages?.blog)
    {
        pageTypeObj = window.brandData.gtm_pageType_pages;
    }
    else
    {
        pageTypeObj = {
            service: ["residential", "commercial", "services", "request-a-cleaning", "contact", "request-job-estimate", "lawn-care-prices", "schedule-free-estimate", "request-appointment", "schedule-service", "request-service", "hvac-service" ],
            blog: ["blog", "expert-tips", "ask-a-pro", "practically-spotless", "cleaning-tips"]
        }

    }
    for (const item of pageTypeObj.service) {
        if (indexUrl.includes(item))
        {
            return "Service";
        }
    }
    for (const item of pageTypeObj.blog) {
        if (indexUrl.includes(item))
        {
            return "Blog";
        }
    }
    return "Other";
}
// 277293: Add Page Type Data layer End

export async function getBrandDetailsFromBrandJson(): Promise<any>
{
    try
    {
        let country = $("#countryCode").val();
        let brand = $("#brandName").val();
        let rootURL = window.location.origin;
        if(brand == "five-star-painting"){
            country = 'us';

        }
        const brandsUrl = `${rootURL}/${country}/en-${country}/_assets/brands.json`;
        const result = await getCachedAPIResponse(brandsUrl, apiCaching.BrandJsonConfig, false);
        if (result.length > 0)
        {
            return result;
        } else
        {
            return [];
        }
    } catch (error: any)
    {
        console.error(`Error in fetching Brand Json response Error message: ${error}`);
    }
}
export function setIstestCondtion(): boolean {
    let IsTest:any;
    var host = window.location.hostname.toLowerCase();
    if(host.includes('nblysbx') || host.includes('nblydev') || host.includes('nblytest')) 
    {
        IsTest = true;
    }else {
        IsTest = false;
    }
    return IsTest;
}

export function addOptInBookingApi() {
     // Determine visibility of US and CA opt-ins
     const isUsOptInVisible = !$("#optin-us").hasClass("hidden");
     const isCaOptInVisible = !$("#optin-ca").hasClass("hidden");
 
     // Handle US-specific checkbox visibility and state
     if (isUsOptInVisible) {
         const isUsReminderChecked = $("#short-form-service-reminder-us").is(":checked");
         window.objOSGlobal.requestBodyValues.optIn = isUsReminderChecked;
         window.objOSGlobal.requestBodyValues.EmailOptOut = false;
         addDataToNotes(`Email OptIn: Yes, Text OptIn: ${isUsReminderChecked ? "Yes" : "No"}`);
         addDataToNotes(`Email OptIn: Yes, Text OptIn: ${isUsReminderChecked ? "Yes" : "No"}`,"Comments");
     }
 
     // Handle CA-specific checkbox visibility and state
     if (isCaOptInVisible) {
         const isCaReminderChecked = $("#short-form-service-reminder-ca").is(":checked");
         window.objOSGlobal.requestBodyValues.optIn = false;
         window.objOSGlobal.requestBodyValues.EmailOptOut = !isCaReminderChecked;
         addDataToNotes(`Email OptIn: ${isCaReminderChecked ? "Yes" : "No"}, Text OptIn:No`);
         addDataToNotes(`Email OptIn: ${isCaReminderChecked ? "Yes" : "No"}, Text OptIn:No`,"Comments");
     }
 
}

export function showOfferAttachedText(){
    const OfferText = window?.objOSGlobal?.requestBodyValues?.OfferText;
    if(OfferText && $('#lead-offer-title').length){
        $('#lead-offer-title').removeClass('d-none');
        $('.lead-offer-text').text(OfferText);
        sessionStorage.setItem('OfferText', OfferText);
    }
    if(!OfferText){
        sessionStorage.setItem('OfferText', "");
    }
}

//Function to append data to notes and / or comments
export function addDataToNotes(inputData:string,appendVar:string='Note'){
    if(inputData){
        window.objOSGlobal.requestBodyValues[appendVar] =  window.objOSGlobal.requestBodyValues[appendVar]? window.objOSGlobal.requestBodyValues[appendVar] + ", " + inputData:inputData;
    }
}

/*Function to append data to a string(particularly to store in notes or comments) 
only when the data holds value. This is to minimise falsy data getting appended to notes and comments.
Arguments:
appendVar - The variable where the data will get appended to.
stringData - The string format which will get appended.
dataVar - the variable whose data needs to get appended to the string.
*/
export function appendToString(appendVar:string,stringData:string,dataVar:string){
    if(dataVar){
        if(window.objOSGlobal.requestBodyValues[appendVar]){
            window.objOSGlobal.requestBodyValues[appendVar] += `, ${stringData}:${dataVar}`;
        }
        else{
            window.objOSGlobal.requestBodyValues[appendVar] = `${stringData}:${dataVar}`;
        }
    }
}

//Function to check if the given zip code Is a valid US zip
export function isZipUS(zip:string):boolean {
    const usZipPattern = /^\d{5}(-\d{4})?$/;
    return usZipPattern.test(zip);
}

//Function to check if the given zip code Is a valid CA zip
export function isZipCA(zip:string):boolean {
    const caZipPattern = /^(?:[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d|[A-Za-z]\d[A-Za-z])$/;
    return caZipPattern.test(zip);
}

//Function to check if 2 strings are equal irrespective of the cases
export function checkStringEquals(str1:string,str2:string){
 if(str1?.toLowerCase() === str2?.toLowerCase()){
    return true;
 }
 return false;
}

//Function to reset the notes and comments section, when any franchiselookup, discovery OR booking api fails.
export function clearNoteAndComments() {
    window.objOSGlobal.requestBodyValues.Note="";
    window.objOSGlobal.requestBodyValues.Comments="";
}
function modifyApplyLocalHref(htmlElement:string,data:any) {
    try{
    let jsonData = JSON.parse(data);
    const { address } = jsonData;
    const applyLocallyLink:any = (document.querySelector(htmlElement) as HTMLElement);
    if (applyLocallyLink && address?.postalCode) {
        applyLocallyLink.setAttribute("href",`https://jobs.aireserv.com/jobs?page_size=10&page_number=1&location=3-${address?.postalCode}&radius=30&locationDescription=zipcode&locationName=${address.postalCode}&sort_by=distance&sort_order=ASC`)
    }
    }catch(error){
        console.log("Error while parsing json Data, "+error);
    }
}
export function updateHeaderApplyLocalLink(){
    let data:any = (document.getElementById('franchise-addr') as HTMLElement)?.textContent;
    if(data){
        applyLocalLinkHeaderArray.forEach((item,index)=>{
            modifyApplyLocalHref(item,data);
        });
    }
}

export function isNotProd(){
    const host = window.location.hostname;
    if(host.includes("nblydev") || host.includes("nblysbx") || host.includes("nblytest")){
        return true;
    }
    return false;
}

export function copyToClipboard(url: string): void {
    navigator.clipboard.writeText(url)
    .then(() => {
        alert(`${url}\nCopied to Clipboard`);
    })
    .catch(err => {
        console.error('Failed to copy text: ', err);
    });
}

export function fspCaEmailOptin(formId: string){
    const optinUs=$(`#${formId} #short-form-service-reminder-us`).closest(".service-reminder-main");
    optinUs.addClass('hidden');
    const optinCa=$(`#${formId} #short-form-service-reminder-ca`).closest(".service-reminder-main");
    optinCa.removeClass('hidden');
}