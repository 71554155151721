import { $ } from "../third-party-imports";
import { apiConfig } from "../config/apiConfig";
import { stopLoader } from "./stopLoader";
import { fullAddressRoundRobinApi } from "./fullAddressRoundRobinApi";
export function getFranchiseWeblocationID(addressParam:any,successHandler:any){
    const requestBodyValues = window?.objOSGlobal?.requestBodyValues;
    const conceptID = requestBodyValues.ConceptId;
    let apiUrl = `${apiConfig.FranchiseLookUpByServiceAddress}${requestBodyValues.PostalCode}&IsRoundRobin=false&conceptID=${conceptID}&RequireAddressOnPartialPostalCode=true&apikey=${window.DEFINE_VALUE.JS_API_KEY}`;
    $.ajax({
        type: "GET",
        url:apiUrl,
        contentType: "application/json",
        dataType: 'json',
        success: successHandler,
        error: function(error:any){
            stopLoader();
            if(error.StatusCode===400 && error.message === 'Full address is needed to narrow down results'){
                apiUrl = apiUrl.replace(requestBodyValues.PostalCode, encodeURIComponent(addressParam));
                fullAddressRoundRobinApi(apiUrl,true,successHandler,'');
                return;
            }
            console.log("error in getFranchiseWeblocationID for zip",error);
            if (requestBodyValues.irvingrewardsform){
            $('.irving-reward-form').addClass('d-none');
            $('.irving-error-thankyou').removeClass('d-none');
            $('.card-title').html(`We're sorry. We don't currently provide service to <span class="noservice-zipcode">${requestBodyValues.PostalCode}</span>`)
            }
            if (requestBodyValues.plgcontactform){
            $('.plg-contact-from').addClass('d-none');
            $('.contact-error-thankyou').removeClass('d-none');
            $('.card-title').html(`We're sorry. We don't currently provide service to <span class="noservice-zipcode">${requestBodyValues.PostalCode}</span>`)
            }
            $('.progress, .progressbar').hide();
            $(".tab-pane.fieldset").removeClass("active");
            $('.scheduler-modal .modal-content').addClass('banner-wizard');
            $('.no-service-screen .head-title').html(`We're sorry. We don't currently provide service to <span class="noservice-zipcode">${requestBodyValues.PostalCode}</span>`);
            $('.no-service-screen').addClass('active');
        }
    });
}