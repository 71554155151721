import { validate_the_step } from "../scheduler/common-os/os-flow-optimize";
import { storeAllfieldsValue } from "../scheduler/common-os/store-value-os-flow";
import { LeadConfirmation } from "../services/LeadConfirmation";
import {$} from "../third-party-imports"
import { getBrandJsonValue } from "./get-brands-json";
import { getStateFun } from "./getStateFun";

window.addEventListener("load", function() {
    if($('section').hasClass('request-form')){
        window.objOSGlobal.requestBodyValues.isRequestForm = true;
        getStateFun();
        getBrandJsonValue();
        $('.js-next-btn').click(function(this:any) {
            requestFormValidation($(this));
        });
    }
    if (window.location.href.indexOf("financing-thank-you") > -1){
        requestFormLeadConfirmation();
    }
});

export function requestFormValidation(obj:any) {
    window.objOSGlobal.requestBodyValues.PostalCode = $('input[name="inputZipCode"]').val();
    let $validate = validate_the_step( obj ); 
    if( ! $validate ) {
        return;
    }
    storeAllfieldsValue(obj);
    storeRequestFormfieldsValue();
    getFranchiseWebLocationId();
};

function storeRequestFormfieldsValue()
{
    let {inputFirstName,inputLastName,inputPhnNumber,inputEmail,inputAddress,inputAddress2,inputcity,inputstate,inputZipCode}  = window.objOSGlobal.requestBodyValues;
    window.objOSGlobal.requestBodyValues.FirstName = inputFirstName;
    window.objOSGlobal.requestBodyValues.LastName = inputLastName;
    window.objOSGlobal.requestBodyValues.Email = inputEmail;
    window.objOSGlobal.requestBodyValues.Phone = inputPhnNumber;
    window.objOSGlobal.requestBodyValues.Address = inputAddress;
    window.objOSGlobal.requestBodyValues.Address2 = inputAddress2;
    window.objOSGlobal.requestBodyValues.City = inputcity;
    window.objOSGlobal.requestBodyValues.State = inputstate;
    window.objOSGlobal.requestBodyValues.PostalCode = inputZipCode;
    window.objOSGlobal.requestBodyValues.requestContactForm = true;
}

function getFranchiseWebLocationId() {
    let zipCode:any = $("#FinancingForm input[name='inputZipCode']").val();
    let ConceptId:any = Number($('#conceptId').val()) ? Number($('#conceptId').val()) : '';
    let apiUrl:any = `${window.DEFINE_VALUE.JS_UnifiedSyncPlatform_URL}/UnifiedSyncPlatform/FranchiseLookUpByServiceAddress?apikey=${window.DEFINE_VALUE.JS_API_KEY}&Address=${zipCode}&IsRoundRobin=true&conceptID=${ConceptId}`;
    $.ajax({
        type: "GET",
        url:apiUrl,
        contentType: "application/json",
        dataType: 'json',
        async:false,
        success: async function(result:any){
            if(result.length > 0){
                let WebLocationId:any = result[0].franchiseWebLocationId;
                window.objOSGlobal.requestBodyValues.franchiseId = result[0].franchiseId;
                window.objOSGlobal.requestBodyValues.WebLocationId = WebLocationId;
                window.objOSGlobal.requestBodyValues.licenseNumber = result[0].licenseNumber;
                window.objOSGlobal.requestBodyValues.IsLeadOnly = true;
                window.objOSGlobal.requestBodyValues.IsEstimate = false;
            
                if(ConceptId ===8){
                  window.objOSGlobal.requestBodyValues.Warranty = true;
                  window.objOSGlobal.requestBodyValues.serviceSkuId = 7989;
                  window.objOSGlobal.requestBodyValues.ServiceId = '2';
                  window.objOSGlobal.requestBodyValues.ServiceSkuIds = [7989];
                  window.objOSGlobal.requestBodyValues.MakeId = 133;
                }
                window.objOSGlobal.requestBodyValues.MrhServiceType = "Residential";
                window.objOSGlobal.requestBodyValues.guyServiceType = "Residential";
                LeadConfirmation();
            }else{
                $('.request-form form').addClass('d-none');
                $('.request-error-modal').removeClass('d-none');
                $('.noservice-zipcode').text(zipCode);
                $('html, body').animate({
                    'scrollTop' : $(".request-error-modal").position().top
                });
            }
        },
        error: function(data:any){
            $('.request-form form').addClass('d-none');
            $('.request-error-modal').removeClass('d-none');
            $('.noservice-zipcode').text(zipCode);
            $('html, body').animate({
                'scrollTop' : $(".request-error-modal").position().top
            });
        }
    });
}


function requestFormLeadConfirmation(){
       let list = displayRequestContactInfo();
       $('.info-list').append(list);
}

function displayRequestContactInfo(){
    let email = sessionStorage.getItem("CEmail");
    let phone = sessionStorage.getItem("CPhone");
    let firstName = sessionStorage.getItem("CFirstName");
    let lastName = sessionStorage.getItem("CLastName");
    let street = sessionStorage.getItem("CAddress");
    let street2 = sessionStorage.getItem("CAddress2");
    let city = sessionStorage.getItem("CCity");
    let state = sessionStorage.getItem("CState");
    let pincode = sessionStorage.getItem("CPostalCode");
    street2 = street2 !='' ? ' '+ street2 : '';
    let address = street + street2;
    let completeAddress = address +'<br/>'+ city + ', '+state + ' '+pincode;
    let fullname = firstName + " " + lastName;
    return`<li> <span> Name : </span> <span><b> ${fullname}</b></span> </li> 
    <li> <span> Email : </span> <span><b> ${email} </b></span> </li>
    <li> <span> Phone : </span> <span><b> ${phone}  </b></span> </li>
    <li> <span> Service Address : </span> <span><b> ${completeAddress}</b></span> </li>`
}

$('#close-service-request-btn').click(function(){
    $('.request-form form').removeClass('d-none');
    $('.request-error-modal').addClass('d-none');
})
