export function validateLocalZipPostalCode(postal:any){
    let country:any = $("#countryCode").val();
    let checkCroleadEnable;
    let brandJsonItems = window.objOSGlobal.requestBodyValues?.brandJsonGlobalObj;
    if(brandJsonItems){
       checkCroleadEnable = brandJsonItems[0]?.enable_cro_enhance_lead_no_zip_match || false ;
    }
    let zipTest:any;
    if($("#conceptCode").val() === 'MRR' && country.toLowerCase() === 'ca' || checkCroleadEnable && country.toLowerCase() ==='ca') {
         zipTest =  /^[A-Za-z]\d[A-Za-z]/.test(postal);
    } else if ($("#conceptCode").val() === 'FSP' && (/^[A-Za-z]\d[A-Za-z]$/.test(postal.trim()))) {
        zipTest = /^[A-Za-z]\d[A-Za-z]/.test(postal.trim());
    } else if ($("#conceptCode").val() === 'FSP' && /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/.test(postal.trim())) {
        zipTest = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/.test(postal.trim());
    }else{
        zipTest = /^([0-9]{5}|[A-Za-z][0-9][A-Za-z] ?[0-9][A-Za-z][0-9])$/.test(postal);
    }
    return zipTest;
}