import { $ } from "../../third-party-imports";
export function asvFormValidation()
{
    $.validator.setDefaults({
        errorElement: "span",
        errorPlacement: function (error: any, element: any)
        {
            error.addClass("error-msg help-block invalid-feedback");
            error.insertAfter(element);
        },
        highlight: function (element: any, errorClass: any, validClass: any)
        {
            $(element)
                .parents(".form-group")
                .addClass("has-error")
                .removeClass("has-success");
        },
        unhighlight: function (element: any, errorClass: any, validClass: any)
        {
            $(element)
                .parents(".form-group")
                .addClass("has-success")
                .removeClass("has-error");
        },
    });

    $("#contactusformasv").validate({
        onkeyup: function (this: any, element: any)
        {
            if ($(element).val() != "" || $(element).val() == "")
            {
                $(element).valid();
            }
        },
        rules: {
            inputFirstName: {
                required: true,
            },
            inputLastName: {
                required: true,
            },
            email: {
                required: true,
                email: true,
            },
            inputPhnNumber: {
                required: true,
                minlength: 14,
                maxlength: 14,
            },
            inputAddress: {
                required: true,
            },
            inputcity: {
                required: true,
                cityValidate:true,
            },
            inputstate: {
                required: true,
            },
            inputZipCode: {
                required: true,
                addressZipValidate: true
            },
        },
        messages: {
            inputPhnNumber: "Please enter a valid phone number",
        },
    });

    ($("#serviceDetails") as any).validate({
        onkeyup: function (this: any, element: any)
        {
            if ($(element).val() != "" || $(element).val() == "")
            {
                $(element).valid();
            }
        },
        rules: {
            referenceDetails: {
                required: $("#referenceDetails").attr("required")?.length ? true : false
            }
        }
    });
}