import { requestEstimate } from "./requestEstimate";
import { fullAddressRoundRobinApi } from "./fullAddressRoundRobinApi";
import { getBrandJsonValue } from "./get-brands-json";
import { phoneFormatter } from "./phoneFormatter";
import { startLoader } from "./startLoader";
import { stopLoader } from "./stopLoader";
import { addDataToNotes, checkStringEquals, clearNoteAndComments, isZipCA, isZipUS } from "./util";

const country:string = $("#countryCode").val() as string;
export function croShortForm(){
    const city =  window.objOSGlobal.requestBodyValues.City;
    const state =  window.objOSGlobal.requestBodyValues.State;
    const address = window.objOSGlobal.requestBodyValues.Address;
    if(address==''){
      window.objOSGlobal.requestBodyValues.Address = "No Address Collected";
    }
    if(!city && !state){
       discoveryApiGetAddressDetails();
    }
 }
 
 phoneFormatter('shortFormPhoneNumber');
 function discoveryApiGetAddressDetails(){
     const postalCode = window.objOSGlobal.requestBodyValues.PostalCode;
     const url = `https://discover.search.hereapi.com/v1/discover?q=${postalCode}&in=countryCode%3AUSA%2CCAN&at=0,0&apiKey=${window.DEFINE_VALUE.JS_Heremap_API_KEY}`;
     $.ajax({
         url,
         dataType: 'json',
         type: 'GET',
         async:false,
         success:function(data) {
             const result  = data.items[0];
             const {city,stateCode} = result.address;
             window.objOSGlobal.requestBodyValues.City = city;
             window.objOSGlobal.requestBodyValues.State = stateCode;
             if(window.objOSGlobal.requestBodyValues.shortFormSubmit){
                    const options: any = {
                        weekday: 'long',
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric'
                    };
                    if (window.objOSGlobal.requestBodyValues.IsLeadOnly)
                        {
                            window.objOSGlobal.requestBodyValues.JobPromisedDate = new Date().toLocaleDateString("en-US", options);
                        }
                    let payloadData = getPayload();
                    callBookingApi(payloadData);
             }
         },
         error: function(error) {
             console.log(error);
             stopLoader();
             closeServiceReminderModal();
             clearNoteAndComments();
         }
     });
 }

//US-343568 Short Form Above Footer JS start
let selectedTab:string="Residential";
export function shortFormTabSwitch(event:JQuery.TriggeredEvent){
    const tabs = document.querySelectorAll('.short-form-tabs a');
    tabs.forEach(tab => {
        // Remove 'active' class from all tabs
        tab.classList.remove('active')
    });
    // Add 'active' class to the clicked tab
    $(event.currentTarget).addClass('active');
    selectedTab = event.currentTarget.dataset.tab;
}
// short form switch tab
$( '.short-form-tab' ).on( "click", function(event:JQuery.TriggeredEvent) {
    shortFormTabSwitch(event)
});

$('#shortFormSubmit').on("click", function (){
    $('.short-form .error-text').addClass('d-none');
    if($("#short-form").valid()){
        let zipcodeVal= $('#short-form #shortFormZipCode').val() as string;
        zipcodeVal = zipcodeVal.trim();
        startLoader();
        getBrandJsonValue();
        if(checkStringEquals(country,'us') && isZipCA(zipcodeVal)){
            $('#service-reminder-modal').modal();
            $('#service-reminder-modal').show();
            stopLoader();
        }
        else{
            addOptinDataOnShortFormSubmit(zipcodeVal,'short-form');
            setShortFormValues();
        }
        
    }
    
});

function setShortFormValues(){
const requestBodyValues=window?.objOSGlobal?.requestBodyValues;
requestBodyValues.shortFormSubmit = true;
const name = splitName($('#short-form #shortFormName').val());
requestBodyValues.FirstName = name.firstName;
requestBodyValues.LastName = name.lastName;
requestBodyValues.Email = $('#short-form #shortFormEmail').val();
requestBodyValues.Phone = $('#short-form #shortFormPhoneNumber').val();
requestBodyValues.Address = $('#short-form #shortFormAddress').val();
requestBodyValues.PostalCode = $('#short-form #shortFormZipCode').val();
addDataToNotes(selectedTab);

// Franchise Lookup call
fullAddressRoundRobinApi(requestBodyValues.PostalCode,false,()=>{
    if(window.objOSGlobal.requestBodyValues.FranchiseId){
        discoveryApiGetAddressDetails();
    }
    else{
        showZipCodeErrorMsg(requestBodyValues.PostalCode);
        stopLoader();
        closeServiceReminderModal();
        clearNoteAndComments();
        }  
    },"");
}

function showZipCodeErrorMsg(PostalCode:number){
    $('.short-form .error-text').removeClass('d-none');
    $('.short-form .error-text span').html(`${PostalCode}`);
}

function splitName(name:any) {
    const parts = name.trim().split(/\s+/);
    let firstName = "";
    let lastName = "";

    if (parts.length === 1) {
        firstName = parts[0];
        lastName = " ";
    } else if (parts.length > 1) {
        firstName = parts.slice(0, -1).join(' ');
        lastName = parts[parts.length - 1];
    }

    return {
        firstName: firstName,
        lastName: lastName
    };
}

function callBookingApi(payload:any){
    startLoader();
    $.ajax({
        url: window.DEFINE_VALUE.bookingEndpoint + "?apikey=" + window.DEFINE_VALUE.JS_API_KEY,
        type: "POST",
        contentType: "application/json",
        dataType: "json",
        data: JSON.stringify(payload),
        success: function (data:any, status:any){
         requestEstimate('request_call_back','short-form','success',selectedTab?selectedTab:'');
         setConfirmationFieldValue(payload);
         const trailingSlash = localStorage.getItem("isTrailingSlash") == "true" ? "/" : "";
         const domainName = window.location.origin.toLowerCase();
         const websiteurl = $('#weblocationId').length ? domainName + "/" + $('#dbaName').val() : domainName;
         window.location.replace(websiteurl + "/" + "confirmation" + trailingSlash);
        },
        error: function (error:any, status:any)
        {
            stopLoader();
            requestEstimate('request_call_back','short-form','fail',selectedTab?selectedTab:'');
            console.log("Booking API failed",error);
            clearNoteAndComments();
        }
    })
}

function getPayload() {
    let requestBodyValues = window?.objOSGlobal?.requestBodyValues;
    let payload = {
        FranchiseId: requestBodyValues?.FranchiseId || "",
        HasSameDayScheduling: requestBodyValues?.HasSameDayScheduling ?? true,
        IsBetaTester: requestBodyValues?.IsBetaTester ?? false,
        IsLocalized: requestBodyValues?.IsLocalized ?? true,
        ConceptId: Number($('#conceptId').val()) || 0,
        UseFranchiseProfileOptInForPOSScheduling: requestBodyValues?.UseFranchiseProfileOptInForPOSScheduling ?? true,
        IsFranchiseOptedIntoPOSScheduling: requestBodyValues?.IsFranchiseOptedIntoPOSScheduling ?? true,
        C4ChannelTypeId: requestBodyValues?.C4ChannelTypeId || 0,
        ConceptCalledId: Number($('#conceptId').val()) || 0,
        ConceptName: requestBodyValues?.ConceptName || "",
        ConceptCode: $('#conceptCode').val() || "",
        C4LeadsEnabled: requestBodyValues?.C4LeadsEnabled ?? true,
        VendorId: requestBodyValues?.brandJsonGlobalObj?.[0]?.vendorId || 0,
        IsTest: requestBodyValues?.IsTest || false,
        VendorName: requestBodyValues?.brandJsonGlobalObj?.[0]?.vendorName || "",
        WebLocationId: requestBodyValues?.WebLocationId || 0,
        Note: requestBodyValues?.Note || "",
        Comments: requestBodyValues?.Comments || "",
        FirstName: requestBodyValues?.FirstName || "",
        LastName: requestBodyValues?.LastName || "",
        Email: requestBodyValues?.Email || "",
        Phone: requestBodyValues?.Phone || "",
        PostalCode: requestBodyValues?.PostalCode || "",
        City: requestBodyValues?.City || "No city found",
        State: requestBodyValues?.State || "No state found",
        Country: requestBodyValues?.Country || "",
        Address: requestBodyValues?.Address || "No Street Address Collected",
        Address2: requestBodyValues?.Address2 || "",
        LeadSource: requestBodyValues?.LeadSource || "",
        LicenseNumber: localStorage.getItem('estlicenseNumber') || requestBodyValues?.licenseNumber || "",
        LeadOrigin: requestBodyValues?.LeadOrigin || "Web",
        CampaignName: requestBodyValues?.CampaignName || "N/A",
        ReferringURL: requestBodyValues?.ReferringURL || document.referrer,
        CurrentPage: window.location.hostname.toLowerCase(),
        CallType: requestBodyValues?.CallType || "",
        ReferralType: requestBodyValues?.ReferralType || "",
        EmailOptOut: requestBodyValues?.EmailOptOut || false,
        SignUpForUpdates: requestBodyValues?.SignUpForUpdates || false,
        Latitude: requestBodyValues?.Latitude || 0,
        Longitude: requestBodyValues?.Longitude || 0,
        IsGoogleAddress: true,
        ServiceID: localStorage.getItem('estServiceId') || 0,
        Warranty: requestBodyValues?.Warranty || false,
        TimeBlock: {
            ConceptId: $('#conceptId').val() || 0,
            JobPromisedDate: requestBodyValues?.JobPromisedDate || "",
            IsAvailable: true,
            IsFranchiseInPOS: true
        },
        NeighborlyLeadSourceId: requestBodyValues?.NeighborlyLeadSourceId || 0,
        ServiceSkuId: localStorage.getItem('estserviceSkuId') || 0,
        IsLeadOnly: requestBodyValues?.IsLeadOnly || false,
        optIn: requestBodyValues?.optIn,
    };

    payload.Note = payload.Note+', '+'5h0r7'
    return payload;
}

function setConfirmationFieldValue(payload:any){
    localStorage.setItem("OSWebLocationId", window.objOSGlobal.requestBodyValues.WebLocationId);
    localStorage.setItem("OSDbaFullName", window.objOSGlobal.paramsUI.doingBusinessAs);
    sessionStorage.setItem('CFirstName', payload.FirstName);
    sessionStorage.setItem('CLastName', payload.LastName);
    sessionStorage.setItem('CAddress', payload.Address);
    sessionStorage.setItem('CAddress2', payload.Address2);
    sessionStorage.setItem('CCity', payload.City);
    sessionStorage.setItem('CState', payload.State);
    sessionStorage.setItem('CPostalCode', payload.PostalCode);
    sessionStorage.setItem('CEmail', payload.Email);
    sessionStorage.setItem('CPhone', payload.Phone);
    sessionStorage.setItem('CLeadOnly', payload.IsLeadOnly);
    sessionStorage.setItem('CServiceRequest', selectedTab);
    sessionStorage.setItem('ServiceModalEnable','');
    sessionStorage.setItem('shortForm', 'true');
}

export function shortFormConfirmationText(){
    $("div.primary-theme-clr.cust-opus-h3-cls:contains('Preferred Date and Time')").addClass('d-none');
    sessionStorage.setItem('shortForm', 'false');
    window.objOSGlobal.requestBodyValues.shortFormSubmit = false;
}

/*****************************OPTIN RELATED CODE STARTS HERE **************************************/
/*When in US domain and US zip, optin checkbox is UNCHECKED 
OR
When in CA domain and US zip, email optin checkbox is CHECKED*/
function usOptInUnchecked(){
    window.objOSGlobal.requestBodyValues.optIn = false;
    window.objOSGlobal.requestBodyValues.EmailOptOut = false;
    addDataToNotes("Text OptIn: No, Email OptIn: Yes");
    addDataToNotes("Text OptIn: No, Email OptIn: Yes","Comments");
}

//When in US domain and US zip, optin checkbox is CHECKED
function usOptInChecked(){
    window.objOSGlobal.requestBodyValues.optIn = true;
    window.objOSGlobal.requestBodyValues.EmailOptOut = false;
    addDataToNotes("Text OptIn: Yes, Email OptIn: Yes");
    addDataToNotes("Text OptIn: Yes, Email OptIn: Yes","Comments");   
}

//When in US domain and CA zip and user agrees for email service reminders
//Call Booking Api
export function usDcaZOptInGetEmail(){
    startLoader();
    closeServiceReminderModal();
    window.objOSGlobal.requestBodyValues.optIn = false;
    window.objOSGlobal.requestBodyValues.EmailOptOut = false;
    addDataToNotes("Text OptIn: No, Email OptIn: Yes");
    addDataToNotes("Text OptIn: No, Email OptIn: Yes","Comments"); 
}

//When in US domain and CA zip and user DOES NOT AGREE for email service reminders
//Call Booking Api
export function usDcaZOptInNoEmail(){
    startLoader();
    closeServiceReminderModal();
    window.objOSGlobal.requestBodyValues.optIn = false;
    window.objOSGlobal.requestBodyValues.EmailOptOut = true;
    addDataToNotes("Text OptIn: No, Email OptIn: No");
    addDataToNotes("Text OptIn: No, Email OptIn: No","Comments"); 
}

//When in CA domain and CA zip, email optin checkbox is CHECKED
function caOptInChecked(){
    window.objOSGlobal.requestBodyValues.optIn = undefined;
    window.objOSGlobal.requestBodyValues.EmailOptOut = false;
    addDataToNotes("Text OptIn: No, Email OptIn: Yes");
    addDataToNotes("Text OptIn: No, Email OptIn: Yes","Comments");  
}

//When in CA domain and CA zip, email optin checkbox is UNCHECKED
function caOptInUnchecked(){
    window.objOSGlobal.requestBodyValues.optIn = undefined;
    window.objOSGlobal.requestBodyValues.EmailOptOut = true;
    addDataToNotes("Text OptIn: No, Email OptIn: No");
    addDataToNotes("Text OptIn: No, Email OptIn: No","Comments");  
}


//When in CA domain and US zip, email optin checkbox is UNCHECKED
function caDusZOptInUnchecked(){
    window.objOSGlobal.requestBodyValues.optIn = false;
    window.objOSGlobal.requestBodyValues.EmailOptOut = true;
    addDataToNotes("Text OptIn: No, Email OptIn: No");
    addDataToNotes("Text OptIn: No, Email OptIn: No","Comments");  
}

export function addOptinDataOnShortFormSubmit(zipcodeVal: string, formId:string) {
    const isUS = checkStringEquals(country, 'us');
    const isCA = checkStringEquals(country, 'ca');
    const isUSZip = isZipUS(zipcodeVal);
    const isCAZip = isZipCA(zipcodeVal);
    let optInCheckbox = document.querySelector(`#${formId} #short-form-service-reminder-us`) as HTMLInputElement;
    if(isCA){
        optInCheckbox = document.querySelector(`#${formId} #short-form-service-reminder-ca`) as HTMLInputElement;
    }
    const isChecked = optInCheckbox?.checked;
    if (isChecked){
        if (isUS && isUSZip){
            usOptInChecked();
        } else if (isCA && isCAZip){
            caOptInChecked();
        } else if (isCA && isUSZip){
            usOptInUnchecked();
        }
    } 
    else if (isUS && isUSZip){
        usOptInUnchecked();
    }
     else if (isCA && isCAZip){
        caOptInUnchecked()
    }
     else if (isCA && isUSZip){
        caDusZOptInUnchecked();
    }
}

//Onclick of Optin Message Popup button
// Yes Button
$('.short-form-wrap #service-reminder-modal .btn-primary').on('click', function() {
    usDcaZOptInGetEmail();
    setShortFormValues();
})
//No Button, Close Modal OR when Optin checkbox is UNCHECKED
$('.short-form-wrap #service-reminder-modal .btn-outline-primary, .short-form-wrap #service-reminder-modal .close-modal-btn close').on('click', function() {
    usDcaZOptInNoEmail();
    setShortFormValues();
})

//Function to close the Service Reminder Popup
function closeServiceReminderModal(){
    if($('#service-reminder-modal')?.is(':visible')){
        $('#service-reminder-modal')?.removeClass('show');
        $('.modal-backdrop')?.hide();
        $('body.mr-rooter.modal-open')?.removeClass('modal-open');
     }
}