import { $ } from "../third-party-imports";

export function digitalDataScript() {
    $(function () {
        try {
            let brandData: any = window.brandData;
            if (brandData) {
                let brandName = $("#conceptCode").val();
                window.digitalData = window.digitalData || [];
                window.digitalData.push({
                    name: brandData?.name,
                    branchof: brandName,
                    telephone: brandData?.nest_phone,
                });
            }
        } catch (error) {
            console.log("error while parsing brand data");
        }
    });
}