import { displayAddress } from "./addressDisplayHandler";
export function footerSocialLinks()
{
    let weblocationID = localStorage.getItem("weblocationId"); //Bug 100211 //$('#weblocationId').val();
    let hostname = window.location.hostname;
    let isLower = (hostname.indexOf("nblydev") != -1 || hostname.indexOf("nblysbx") != -1);
    if (weblocationID)
    {
        $('.footer-social-icons').remove();
        $('.nav_social-icons').remove();
        var apiDomain = isLower ? window.DEFINE_VALUE.JS_API_URL : "https://api.neighborly.com";
        var apiKey = isLower ? window.DEFINE_VALUE.JS_API_KEY : "pneiweb-9pbaOywNKQj853D";
        var digitalOrchestrations = isLower ? window.DEFINE_VALUE.JS_DigitalOrchestrations_URL : "digitalorchestrations-api";

        var transactionID = sessionStorage.getItem('corelationID');
        var sessionID = sessionStorage.getItem('sessionID');
        let correlationId = `correlationId=${transactionID}&sessionId=${sessionID}`;

        if ($('.footer-social-icons .list-inline.social-icons-list li').length == 1)
        {
            $('.footer-social-icons ul.list-inline.social-icons-list').addClass('social-hide-before');
        }
        $.ajax({
            type: "GET",
            //url: `${apiDomain}/${franchiseGroup}/v1/public/fullattribute/franchiseWeblocations/search?franchiseWeblocationId=${weblocationID}&apikey=${apiKey}`,
            url: `${apiDomain}/${digitalOrchestrations}/v1/public/franchiseGroupOrchestrator/${weblocationID}/0`,
            contentType: "application/json",
            async: false,
            dataType: 'json',
            headers: {
                'apikey': apiKey,
                'X-Corellation-Id': correlationId
            },
            success: function (result: any)
            {
                if(result){
                    localStorage.setItem("fullAttrOnLoad", JSON.stringify(result.fullAttributeSearch));
                    const data = result.fullAttributeSearch;
                    const brandName = $('#brandName').val();
                    if(data){
                        if (data.franchiseWebLocationLinks?.length)
                        {
                        data.franchiseWebLocationLinks.sort(function (a: any, b: any)
                        {
                            return parseFloat(a.linkTypeId) - parseFloat(b.linkTypeId);
                        });
                        let colmd4Elements = data.franchiseWebLocationLinks.map((item: any) =>
                        {
                            var linkType = item.linkTypeDescription.toLowerCase();
                            if(linkType == "facebook" || linkType == "twitter" || linkType == "pinterest" || linkType == "youtube" || linkType == "instagram"|| linkType == "linkedin"){
                            return `<li class="list-inline-item">
                            <a href=${item.description} class="icon-secondary footer-${item.linkTypeDescription.toLowerCase()}" target="_blank" title="Open in New tab">
                            <img src="/us/en-us/grounds-guys/_assets/images/socialIcons/${item.linkTypeDescription.toLowerCase()}.svg" alt="${item.linkTypeDescription} logo icon" fetchpriority="low" loading="lazy" height="27px" width="27px">
                            </a>
                            </li>`
                            }
                        });
                        let headersocialElements = data.franchiseWebLocationLinks.map((item: any) =>
                            {
                                var linkType = item.linkTypeDescription.toLowerCase();
                                if(linkType == "facebook" || linkType == "twitter" || linkType == "pinterest" || linkType == "youtube" || linkType == "instagram"|| linkType == "linkedin"){
                                return `<li>
                                <a href=${item.description} class="icon-secondary footer-${item.linkTypeDescription.toLowerCase()}" target="_blank" title="Open in New tab">
                                <img src="/us/en-us/${brandName}/_assets/images/socialIcons/${item.linkTypeDescription.toLowerCase()}_icon.svg" alt="${item.linkTypeDescription} logo icon" fetchpriority="low" loading="lazy" height="27px" width="27px">
                                </a>
                                </li>`
                                }
                            })
                        $('.links-section .col-md-3.col-6.order-col-2').empty().append('<div class="footer-social-icons"></div>');
                        $('.footer-social-icons').empty().append(`<ul class="list-inline social-icons-list"></ul>`);
                        $('.footer .list-inline.social-icons-list').empty().append(colmd4Elements);
                        $('.social-icons').empty().append('<div class="nav_social-icons mobile-only"></div>');
                        $('.social-icons .nav_social-icons').empty().append(`<ul class="d-flex"></ul>`);
                        $('.social-icons .nav_social-icons ul.d-flex').empty().append(headersocialElements);
                        $('.fsp-social-icons .social-icons-list').empty().append(colmd4Elements);
                    }
                    else{
                        $('.fsp-social-icons .social-icons-list').empty();
                    }
                    //Set phone number in global 
                    var calltrackObj = data.callTrackings?.filter((obj:any) => obj?.franchiseWebLocationCallTrackingTypeID == 2);
                    var phoneNumber = calltrackObj[0]?.callTrackingNumber ? calltrackObj[0]?.callTrackingNumber.toString().match(/(\d{3})(\d{3})(\d{1}.*)/) : null;
                    if (phoneNumber){
                        phoneNumber = "(" + phoneNumber[1] + ") " + phoneNumber[2] + "-" + phoneNumber[3];
                    }
                    window.objOSGlobal.paramsUI.brandPhoneNumber = phoneNumber;
                    //Set phone number in blog 
                    $("#blog-phone-number").attr('href', 'tel:' + phoneNumber);
                    $('#blog-phone-number').text(phoneNumber);
                    displayAddress(data); //(+)[US:101264]
                }
                }
            },
            error: function (error: any)
            {
            }
        });
    }
}