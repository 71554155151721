import { $ } from "../third-party-imports"

window.addEventListener("load", function(){
    $(".print-btn").click(function (this:any, e:any)
    {
        var contents = $('<div>').append($(this).parent().closest("section").clone()).html();
        var urlLink = window.location.href.split('/');
        var isWebSite = urlLink[3] === "ca" ? "ca" : "us";
        var brandCss = isWebSite == 'ca' ? "/us/en-us/brand/_assets/css/"+urlLink[4]+".css" : "/us/en-us/brand/_assets/css/"+urlLink[3]+".css"; 
        var frame1 = $<HTMLIFrameElement>('<iframe />');
        frame1[0].name = "frame1";
        frame1.css({ "position": "absolute", "width": "100%", "size": "A4 landscape", "margin": "5%" });
       
        $("body").append(frame1);
        var frameDoc = frame1[0].contentWindow ? frame1[0].contentWindow : frame1[0].contentDocument["document"] ? frame1[0].contentDocument["document"] : frame1[0].contentDocument;
        frameDoc.document.open();
        // var is_chrome = Boolean(frameDoc.chrome);    
        frameDoc.document.write(`<html><head><title></title>`);  //Create a new HTML document.  
        //Append the external CSS file.
        frameDoc.document.write(`
        <link rel="preload" href="/us/en-us/brand/_assets/css/bootstrap.min.css" as="style">
        <link rel="preload" href="/us/en-us/brand/_assets/css/shared.css" as="style">
        <link rel="preload" href="${brandCss}" as="style">
    
        <link href="/us/en-us/brand/_assets/css/bootstrap.min.css" rel="stylesheet" type="text/css" media="print" />
        <link href="/us/en-us/brand/_assets/css/shared.css" rel="stylesheet" type="text/css" media="print" />
        <link href="${brandCss}" rel="stylesheet" type="text/css" media="print" />
        <style>
        .btn-group li {
            display: none !important;
        }
        .btn-group li:first-child {
            display: block !important;
        }  
        .btn-group, .btn-group-vertical {
            display: block !important;
        }  
        .service-coupon-section {
            padding: 7mm;
        }
        /*--------------------For Print Default Setting--------------------*/
        @page {
            margin:0mm;
          }
          * {
            -webkit-print-color-adjust: exact !important;   /* Chrome, Safari */
            color-adjust: exact !important;                 /*Firefox*/
        }
        @media print { 
            @-moz-document url-prefix() {
        
             .btn-group li {
                display: none !important;
             }
             .btn-group li:first-child {
                display: block !important;
             }
                }
        }
        </style>
        `);
        frameDoc.document.write('</head>');
        frameDoc.document.write('<body class="wf-loading">');    
        frameDoc.document.write(contents); //Append the DIV contents.
        frameDoc.document.write('</body></html>');
    
        setTimeout(function ()
            {
                frameDoc.document.close(); // necessary for IE >= 10
                frameDoc.focus(); // necessary for IE >= 10
                frameDoc.print();
                frame1.remove();
                frameDoc.document.close();
            }, 250);
    
    });
    $(".print-coupon").click(function (this:any, e:any)
    {
        const contents:string = $(this).parent().closest(".sp-offers-item").clone().html();
        const updatedContents:string = contents.toString().replace('d-none', '');
        const frame1 = $<HTMLIFrameElement>('<iframe />');
        frame1[0].name = "frame1";
        frame1.css({ "position": "absolute", "width": "100%", "size": "A4 landscape", "margin": "5%", "display":"none"});
        const backgroundCol=$('.sp-offers-main').css('background-color');
        const textCol=$('.sp-offers-main').css('color');
        $("body").append(frame1);
        const frameDoc = frame1[0].contentWindow ? frame1[0].contentWindow : frame1[0].contentDocument["document"] ? frame1[0].contentDocument["document"] : frame1[0].contentDocument;
        frameDoc.document.open();
        // var is_chrome = Boolean(frameDoc.chrome);    
        frameDoc.document.write(`<html><head><title></title>`);  //Create a new HTML document.  
        //Append the external CSS file.
        frameDoc.document.write(`
        <link rel="preload" href="/us/en-us/brand/_assets/css/bootstrap.min.css" as="style">
        <link rel="preload" href="/us/en-us/brand/_assets/css/shared.css" as="style">
    
        <link href="/us/en-us/brand/_assets/css/bootstrap.min.css" rel="stylesheet" type="text/css" media="print" />
        <link href="/us/en-us/brand/_assets/css/shared.css" rel="stylesheet" type="text/css" media="print" />
        <style>
        .sp-offers-actions, .print-coupon-mobile, .secondary-btn.claim-offer, .sp-offers-disclaimer{
            display: none !important;
        }
        .sp-offers-main {
            position: relative;
            border-radius: 0.5rem;
            padding: 1.5rem;
            margin: 2rem;
            box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.10), 0px 4px 6px 0px rgba(0, 0, 0, 0.05);
            background-color: ${backgroundCol};
            border-color: #d9272e;
            color: ${textCol};
            width:300px;
        }
        .secondary-btn.claim-offer {
            display: block;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 150%;
            text-align: center;
            border-radius: 0px 0px 8px 8px;
        }
        .secondary-btn.claim-offer:hover {
            color: #fff;
            text-decoration: none;
        }
        .sp-offers-details .sp-offer {
            font-size: 30px;
            font-style: normal;
            line-height: 120%;
            margin-bottom: 0;
        }
        .sp-offers-details .sp-offer-desc {
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            margin: 0;
        }
        .sp-offers-item .sp-offers-main .expire-mobile {
            display: block;
        }
        .sp-offers-item .sp-offers-main {
            border-radius: 8px 8px 0px 0px;
        }
        .sp-offers-disclaimer-print {
            font-size: 12px;
        }
        .sp-offers-disclaimer-print p {
            padding-top:10px;
        }
        .sp-offers-disclaimer-print a {
            text-decoration-line: underline;
        }
        /*--------------------For Print Default Setting--------------------*/
        @page {
            margin:0mm;
          }
          * {
            -webkit-print-color-adjust: exact !important;   /* Chrome, Safari */
            color-adjust: exact !important;                 /*Firefox*/
        }
        @media print { 
            @-moz-document url-prefix() {
        
             .btn-group li {
                display: none !important;
             }
             .btn-group li:first-child {
                display: block !important;
             }
                }
        }
        </style>
        `);
        frameDoc.document.write('</head>');
        frameDoc.document.write('<body class="wf-loading">');    
        frameDoc.document.write(updatedContents); //Append the DIV contents.
        frameDoc.document.write('</body></html>');
    
        frameDoc.document.close();    
        frameDoc.focus(); // necessary for IE >= 10
            frameDoc.print();
     
            

    
    });
    // Print option for stack view
    $(".print-coupon-desktop").click(function (this:any, e:any)
    {
        const contents:string = $(this).parent().closest(".sp-offers-item").clone().html();
        const updatedContents:string = contents.toString().replace('d-none', '');
        const frame1 = $<HTMLIFrameElement>('<iframe />');
        frame1[0].name = "frame1";
        frame1.css({ "position": "absolute", "width": "100%", "size": "A4 landscape", "margin": "5%", "display":"none"});
        const backgroundCol=$('.sp-offers-main').css('background-color');
        const textCol=$('.sp-offers-main').css('color');
        $("body").append(frame1);
        const frameDoc = frame1[0].contentWindow ? frame1[0].contentWindow : frame1[0].contentDocument["document"] ? frame1[0].contentDocument["document"] : frame1[0].contentDocument;
        frameDoc.document.open();
        frameDoc.document.write(`<html><head><title></title>`);  //Create a new HTML document.  
        //Append the external CSS file.
        frameDoc.document.write(`
        <link rel="preload" href="/us/en-us/brand/_assets/css/bootstrap.min.css" as="style">
        <link rel="preload" href="/us/en-us/brand/_assets/css/shared.css" as="style">
    
        <link href="/us/en-us/brand/_assets/css/bootstrap.min.css" rel="stylesheet" type="text/css" media="print" />
        <link href="/us/en-us/brand/_assets/css/shared.css" rel="stylesheet" type="text/css" media="print" />
        <style>
        .print-coupon-mobile, .secondary-btn.claim-offer, .sp-offers-disclaimer, .sp-offers-cta, .expire-mobile{
            display: none !important;
        }
        .sp-offers-main {
            background-color: ${backgroundCol};
            position: relative;
            border-radius: .5rem;
            padding: 1.5rem;
            margin: 2rem;
            box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, .1), 0px 4px 6px 0px rgba(0, 0, 0, .05);
            border-color: #d9272e;
            color: ${textCol};
            
            .sp-offers-data {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                
                .sp-offers-details {
                    margin-top: .5rem;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: .5rem;
                    img {
                        max-width: 100%;
                        height: auto;
                    }
                }
                .sp-offers-actions {
                    text-align: right;
                    .sp-offer-expire {
                        font-size: .875rem;
                        line-height: 1.25rem;
                        font-weight: 400;
                    }
                }
            }
            
        .sp-offers-disclaimer-print {
            font-size: 12px;
            p {
                padding-top:10px;
            }
            a {
                text-decoration-line: underline;
            }
        }
    }
        /*--------------------For Print Default Setting--------------------*/
        @page {
            margin:0mm;
          }
          * {
            -webkit-print-color-adjust: exact !important;   /* Chrome, Safari */
            color-adjust: exact !important;                 /*Firefox*/
        }
        @media print { 
            @-moz-document url-prefix() {
        
             .btn-group li {
                display: none !important;
             }
             .btn-group li:first-child {
                display: block !important;
             }
                }
        }
        </style>
        `);
        frameDoc.document.write('</head>');
        frameDoc.document.write('<body class="wf-loading">');    
        frameDoc.document.write(updatedContents); //Append the DIV contents.
        frameDoc.document.write('</body></html>');
        frameDoc.document.close();    
        frameDoc.focus(); // necessary for IE >= 10
        frameDoc.print();
    })
});