import {$} from '../third-party-imports';
import {photoObject} from './lightbox-gallery';
export function updateGallery(selector:any) {
	
	let $sel = selector;
	photoObject.current_image = $sel.data('image-id');
	$('#imageGalleryTitle').text($sel.data('title'));
	$('#imageGalleryImage').attr('src', $sel.data('image'));
	$('#imageGalleryImage').attr('alt', $sel.data('alt'));
	//disableButtons(counter, $sel.data('image-id'));
}