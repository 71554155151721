import { $ } from "../third-party-imports";
import { checkZipLocalSite } from "../util/checkZipLocalSite";
import { servieModal } from "../util/get-brands-json";
import { bookingApiPayload } from "./booking-api-payload";
import {bookingApiConfirmationScreen} from "./booking-api-confirmation-screen";
import { bookingApiSuccessHandlerData } from "./booking-api-success-handler";
import { bookingFailureHandlerData } from "./booking-api-failure-handler";
import { onlineThreeProgressBar } from "./online-three-progressbar";
import { gtmEventBrandWise } from "./gtm-event-brand";
import { gtmCustomEventLeadFlow } from "../util/gtm-custom-event-lead-flow";

export function plgSchedulerFlow(){
    $('#plg-contact-information input[name="glasstype"]').off('change').on('change', function (this: any){
        $("#glasstype-new-error").remove();
    });
    $('#plg-vechicle-information input[name="replace-glasstype"]').off('change').on('change', function (this: any){
        $("#glasstype-checkbox-error").remove();
    });
    $('#plg-vechicle-information input[id="glasstype3"]').off('change').on('change', function (this: any){
        if(!$('#glasstype3').is(":checked")){
            $("#glasstype-checkbox-error").remove();
        }
    });
    $('.js-next-btn').click(async function(this:any) {
        let nextId = $(this).parents('.tab-pane').next().attr("id");
        let selectServiceType = $(".glass-radio-boxs input:radio[name=customerType]:checked").attr('id');
        if (nextId == "stepfour") {
            var pageType = $('#pageType').val();
            if(pageType=='servicePage'||pageType=='categoryPage'){
                return;
             }
            $('.scheduler-modal .modal-content').addClass('banner-wizard');
        }
        else if(nextId == "step3"){
            
            if($("#plg-contact-information").valid()){
            $('.scheduler-modal .modal-content').removeClass('banner-wizard');
            var changeZip = sessionStorage.getItem('changeZip');
            if(changeZip){
                checkZipLocalSite(nextId);
                return;
            }

            plgContactInformation();
            const formType:any = $(".glass-radio-boxs input:radio[name=customerType]:checked").attr('id');
            const formSubmissionType:any = $("#"+formType).next().next().text();
            sessionStorage.setItem("formType",formSubmissionType.trim());
            addFormTypetoDataLayer();
            if(selectServiceType =="Glass1"){
                onlineThreeProgressBar();
                $('[href="#' + nextId + '"]').tab('show');
                $('.payment-info .js-next-btn').html("Submit "+$('.js-next-btn img')[0].outerHTML);
            }else{
                    let url = window.DEFINE_VALUE.bookingEndpoint + "?apikey=" + window.DEFINE_VALUE.JS_API_KEY;
                    let payload  = bookingApiPayload();                
                    bookingApiConfirmationScreen(payload,url,function(requesData:any){
                        bookingApiSuccessHandlerData(requesData);
                    },function(status:any){
                        bookingFailureHandlerData(status);
                    });         
             }
            } else{
                $('.contact-information-screen').addClass('active');
            }
            
        }
        else if(nextId =="step4"){
            var glassType3 = true;
            if($('#glasstype3').is(":checked")){
                if(!$('#replace-glasstype1,#replace-glasstype4,#replace-glasstype2,#replace-glasstype5,#replace-glasstype3,#replace-glasstype6,#replace-glasstype7').is(":checked")){
                    //$('.plg-vechicle-information #glasstype-error').css('display','inline');
                    glassType3 = false;
                }
            }
            if($("#plg-vechicle-information").valid() && glassType3){                
                vehicleInformation();
                $('[href="#' + nextId + '"]').tab('show');
            }
            if(!glassType3) {
                $(".glassCheckBox .d-block label:first").after('<span id="glasstype-checkbox-error" class="error error-msg help-block invalid-feedback" style="display: inline;">This field is required.</span>');     
            }
        }
        else{
            $('[href="#' + nextId + '"]').tab('show');
        }
    })
    $('.js-previous-btn').click(function(this:any) {
        let prevId = $(this).parents('.tab-pane').prev().attr("id");
            if(prevId =='step1'){
                if(sessionStorage.getItem('serCatBack')){
                  $('.scheduler-modal .modal-content').addClass('banner-wizard');
                  $('.tab-pane.fieldset').removeClass('active');
                  $('.zip-entry-screen').addClass('active');
                  sessionStorage.removeItem('serCatBack')
                  sessionStorage.setItem('servicePageClick','true');
                }else if(sessionStorage.getItem('serCatBtnBack')){
                   $('.scheduler-modal .modal-content').addClass('banner-wizard');
                   $('.tab-pane.fieldset').removeClass('active');
                   $('.service-list-screen').addClass('active');
               }else{
                    $('.tab-pane.fieldset').removeClass('active');
                    if(servieModal.enable){
                        $('.schedulerModalLabel').addClass('d-none');
                        $('.schedulerModalLabel').empty();
                        $('.zip-entry-screen').addClass('active');
                    }
                    else{
                        $('.service-search-screen').addClass('active');
                    }
                    $('.scheduler-modal .modal-content').addClass('banner-wizard');
                }
            }else if(prevId=='step2'){
                $('.progress, .progressbar').hide();
                $('[href="#' + prevId + '"]').tab('show');
            }
            else{
                $('[href="#' + prevId + '"]').tab('show');
            }
            return;
    })
    $('a[data-toggle="tab"]').on('shown.bs.tab', function(e:any) {
        let step = $(e.target).data('step');
        let percent:any = ((parseInt(step) / 3) * 100).toFixed(2);
        switch (step) {
            case 1:
                percent = 0;
                $('.progress-bar').css({ width: percent + '%' });
                break;
            case 2:
                percent = 5.2;
                $('.progress-bar').css({ width: percent + '%' });
                break;
            case 3:
                percent = 53;
                $('.progress-bar').css({ width: percent + '%' });
                break;
            case 4:
                percent = 100;
                $('.progress-bar').css({ width: percent + '%' });
                break;
            case 7:
                percent = 100;
                $('.progress-bar').css({ width: percent + '%' });
                $(".progress").addClass("d-none");
                $(".progressbar").addClass("d-none");
                $(".modal-header-two").addClass("d-none");
                $(".modal-header-one").addClass("d-none");
                break;
        }
    })

    $('input:radio[name=customerType]').change(function(this:any) {
        if (this.id == 'Glass1') {
           sessionStorage.setItem('glass-type-auto','Yes');
           $('.service-information').addClass('d-none');
           $('.plg-contact-screen .js-next-btn').html("Next "+$('.js-next-btn img')[0].outerHTML);
           window.objOSGlobal.requestBodyValues.ServiceText = "Auto Glass Appointment";
        }else{
           sessionStorage.setItem('glass-type-auto','No');
           $('.service-information').removeClass('d-none');
           $('.plg-contact-screen .js-next-btn').html("Submit "+$('.js-next-btn img')[0].outerHTML);
           if (this.id == 'Glass2') {
               window.objOSGlobal.requestBodyValues.ServiceText = "Flat Glass Estimate";
           }else{
            window.objOSGlobal.requestBodyValues.ServiceText = "Flat Glass Estimate Commercial";
           }
           
        }
    });
}

export function plgContactInformation(){
    let firsName = $("#plg-contact-information input[name='firstname']").val();
    let lastName = $("#plg-contact-information input[name='lastname']").val();
    let email = $("#plg-contact-information input[name='email']").val();
    let phoneNumber = $("#plg-contact-information input[name='inputPhnNumber']").val();
    let address = $("#plg-contact-information input[name='address']").val();
    let apartment = $("#plg-contact-information input[name='apartment']").val();
    let city = $("#plg-contact-information input[name='city']").val();
    let zipcode  = $("#plg-contact-information input[name='postal']").val();
    let state  = $("#plg-contact-information select[name='state']").val();
   
    let referenceDetails  = $('select[name="howDidHereAbout"] option:selected').val();
    let serviceNeeds = $("#plg-contact-information textarea[name='serviceNeeds']").val();
    window.objOSGlobal.requestBodyValues.FirstName = firsName;
    window.objOSGlobal.requestBodyValues.LastName = lastName;
    window.objOSGlobal.requestBodyValues.Email = email.trim();
    window.objOSGlobal.requestBodyValues.Phone = phoneNumber;
    window.objOSGlobal.requestBodyValues.Address = address;
    window.objOSGlobal.requestBodyValues.Address2 = apartment;
    window.objOSGlobal.requestBodyValues.City = city;
    window.objOSGlobal.requestBodyValues.State = state;
    window.objOSGlobal.requestBodyValues.PostalCode = zipcode;
    window.objOSGlobal.requestBodyValues.LeadSourceId = referenceDetails;
    window.objOSGlobal.requestBodyValues.NeighborlyLeadSourceId = referenceDetails;
    window.objOSGlobal.requestBodyValues.Message = serviceNeeds;
    // var addressParamsVal:any = address + "&" + apartment + "&" + city + "&" + state + "&" + window.objOSGlobal.requestBodyValues.PostalCode;
    apartment = apartment ? apartment + " ": "";
    var addressParamsVal:any = address + " " + apartment +city + " " + state + " " + window.objOSGlobal.requestBodyValues.PostalCode;
    return addressParamsVal
}

export function vehicleInformation(){
    let year = $("#plg-vechicle-information input[name='year']").val();
    let make = $("#plg-vechicle-information input[name='make']").val();
    let model = $("#plg-vechicle-information input[name='model']").val();
    let vin  = $("#plg-vechicle-information input[name='vin']").val();
    let bodyStyle = $("#plg-vechicle-information input[name='style']").val();
    let licensePlate  = $("#plg-vechicle-information input[name='licensePlate']").val();
    window.objOSGlobal.requestBodyValues.Year = year;
    window.objOSGlobal.requestBodyValues.Make = make;
    window.objOSGlobal.requestBodyValues.Model = model;
    window.objOSGlobal.requestBodyValues.VIN = vin;
    window.objOSGlobal.requestBodyValues.BodyStyle = bodyStyle;
    window.objOSGlobal.requestBodyValues.LicensePlate = licensePlate;    
    window.objOSGlobal.requestBodyValues.serviceRequested = serviceRequested();
}
$('.checkbox-group .d-block input:checkbox[name=glasstype]').on("click", function(this: any) {
    var ischecked= $(this).is(':checked');
    if(ischecked) {
        $('.custom-checkbox .form-check').removeClass('d-none');
    } else {
        $('.custom-checkbox .form-check').addClass('d-none');
    }
});

function serviceRequested() {
    let serviceRequestArr:any = [];
	let getCheckBoxItem:any =  document.querySelectorAll('.glassCheckBox input[type="checkbox"]');
    getCheckBoxItem.forEach((item:any,index:any)=>{
        if($("#" + item.id).prop('checked')){
          let getCheckBoxLabel = $("#" + item.id).next().text();
          serviceRequestArr.push(getCheckBoxLabel)
        }
    })
    return serviceRequestArr;
}

export function payementInformationGet(){
    let insuranceCompany = $(".insurance-company-service input[name='insuranceCompany']").val();
    let policyNumber = $(".insurance-company-service input[name='policyNumber']").val();
    let deductibleAmount = $(".insurance-company-service input[name='deductibleAmount']").val();

    window.objOSGlobal.requestBodyValues.InsuranceCompany = insuranceCompany;
    window.objOSGlobal.requestBodyValues.DeductibleAmount = deductibleAmount;
    window.objOSGlobal.requestBodyValues.PolicyNumber = policyNumber;
    window.objOSGlobal.requestBodyValues.Note = window.objOSGlobal.requestBodyValues.Note + $("#dropoff1").is(':checked') ? "Drop-off Service" : "Mobile Service : " + addressMobService()

}

export function addressMobService(){
    let getInputstems:any =  document.querySelectorAll('.address-mob-service input,.address-mob-service select');
    let addressMobServiceVal:any ="";
    getInputstems.forEach((item:any,index:any)=>{
        if(item.name){
            let mobDetails =  $('.address-mob-service '+item.localName+'[name="'+item.name+'"]').val();
            addressMobServiceVal = addressMobServiceVal + '\n'+ mobDetails;
            sessionStorage.setItem(item.name+'Mob',mobDetails);
        }
    });
    return addressMobServiceVal;
}

export function leadConfirmationCallApiPlg(){
    // let glassTypeAuto = sessionStorage.getItem('glass-type-auto');
    // if(glassTypeAuto =='No'){
    //     $(".appointment-contact .col-sm-4:not(:first-child)").addClass("d-none");
    // }
    let plgName = sessionStorage.getItem('plg-name');
    let plgEmail = sessionStorage.getItem('plg-email');
    let plgPhone = sessionStorage.getItem('plg-phone');
    let plgAddress = sessionStorage.getItem('plg-address');
    let plgVehicle = sessionStorage.getItem('plg-vehicle');
    let plgVin = sessionStorage.getItem('plg-vin');
    let plgBodyStyle = sessionStorage.getItem('plg-style')
    let plgLicense = sessionStorage.getItem('plg-license');
    let plgGlass = sessionStorage.getItem('plg-glass');
    let plgReplace = sessionStorage.getItem('plg-replace');
    let plgService = (sessionStorage.getItem('typeOfServiecPlg') == "dropoff2") ? "Mobile Service for:<br>" + sessionStorage.getItem('plg-service') : "Drop-off Service";
    let plgPayment = sessionStorage.getItem('plg-payment');
    $('.plg-name label').text(plgName);
    $('.plg-email label').text(plgEmail);
    $('.plg-phone label').text(plgPhone);
    $('.plg-address label').html(plgAddress);
    $('.plg-vehicle label').html(plgVehicle);
    if(plgBodyStyle !=""){
        $('.plg-style label').html(plgBodyStyle);
    }else{
        $('.plg-style').addClass('d-none');
    }
    if(plgVin !=""){
        $('.plg-vin label').html(plgVin);
    }else{
        $('.plg-vin').addClass('d-none');
    }
    if(plgLicense !=""){
        $('.plg-license label').html(plgLicense);
    }else{
        $('.plg-license').addClass('d-none');
    }
    if(plgReplace){
        let plgReplaces = plgReplace.split(',');
        let glass_type = ['Replace Other Glass', 'Windshield Replacement', 'Windshield Repair (Damage is smaller than a quarter and not in your field of vision)', 'Replace Wiper Blades']
        let trimPlgReplaces = plgReplaces.map(element => element.trim())
        if(trimPlgReplaces){
            let plg_Replace = trimPlgReplaces.filter(element => !glass_type.includes(element));
            $('.plg-replace label').text(plg_Replace);
        }else{
            $('.plg-replace').addClass('d-none');
        }
    }
    if(plgReplace){
        let plgReplaces = plgReplace.split(',');
        let glass_type = ['Replace Other Glass', 'Windshield Replacement', 'Windshield Repair (Damage is smaller than a quarter and not in your field of vision)', 'Replace Wiper Blades']
        let trimPlgGlass = plgReplaces.map(element => element.trim())
        if(trimPlgGlass){
            let plg_Glass = trimPlgGlass.filter(element => glass_type.includes(element));
            $('.plg-glass label').text(plg_Glass);
        }else{
            $('.plg-glass label').html(plgGlass);
        }
    }
    if(plgService){
        $('.plg-service label').html(plgService);
    }else{
        $('.plg-service').addClass('d-none');
    }
    $('.plg-payment label').html(plgPayment);
    const OfferText=sessionStorage.getItem('OfferText');
    if(OfferText){
        $('#lead-offer-title').removeClass('d-none');
        $('.lead-offer-text').text(OfferText);
    }
    const apiConfig = sessionStorage.getItem('fakeSubmitPayload')
    if(apiConfig){
        console.log("API Config DATA-> ",JSON.parse(apiConfig));
    }
}

export function addFormTypetoDataLayer () {
     let brandCode =$("#conceptCode").val().toUpperCase();
     let formSubmissionType:any = sessionStorage.getItem("formType");
     if(dataLayer?.some((item:any)=>item?.event === "custEv_LocalDataLayer")) {
        dataLayer.splice(dataLayer.findIndex((item:any) => item?.event === "custEv_LocalDataLayer") , 1);
        if(gtmEventBrandWise(brandCode)){
            let leadObj = {
                event:'custEv_LocalDataLayer',
                locationID: window.objOSGlobal.requestBodyValues?.WebLocationId,
                locationDBA:window.objOSGlobal.paramsUI?.doingBusinessAs,
                locationZipcode:window.objOSGlobal.requestBodyValues?.PostalCode,
                licenseNumber:window.objOSGlobal.requestBodyValues?.licenseNumber,
                form_submission_type:formSubmissionType
            }
            gtmCustomEventLeadFlow(leadObj);
        }
     }
}
