import { $ } from "../third-party-imports";

window.addEventListener("load", function(){
    $('.search-box form button').prop("disabled",true).addClass("disble-btn");

    $(document).on('keyup','.search-box form input[name="search"]',function (this: any,e:any){
        if (e.which === 32 && !this.value.length) {
            e.preventDefault();
        }
        var inputValue = e.charCode;
        if(!(inputValue >= 65 && inputValue <= 122) && (inputValue != 32 && inputValue != 0)){
            e.preventDefault();
        } 
        if (e.ctrlKey && e.key === 'a') {
            return;
        }   
        let serchTermVal: any = this.value;
        sessionStorage.setItem('searchInput',serchTermVal);
        if(serchTermVal.length>0){
            $('.search-box form button').prop("disabled",false).removeClass("disble-btn");
            if (e.keyCode === 13) {
                $(".search-box .locate-form-wrapper .locate-form button").click();
            }
        }else{
            $('.search-box form button').prop("disabled",true).addClass("disble-btn");
        }
       
    });
    
    $(document).on("click",".search-box form button",function (this:any,e:any){
        e.preventDefault();
        let dbaName = $('#dbaName').val() ? $('#dbaName').val() : '';
        let searchVal =  sessionStorage.getItem('searchInput');
        let searchTerm = searchVal.replace(/\s/g, '-') ;
        let origin = window.location.origin;
        let url = `${origin}/${dbaName}/services/search/${searchTerm}`;
        window.location.href = url;
    });
    
    $(document).on('click', 'input[type=search]',function(){
        $(document).on('search', 'input[type=search]', function(this:any){
          if(!this.value){
            $('.search-box .locate-form-wrapper .locate-form button').prop("disabled",true).addClass("disble-btn");
          }
        });
        setTimeout(function() {
          $('input[type=search]').off('search');
        }, 1);
    });

});