import { $ } from "../third-party-imports";
export function ValidateZipPostalCode(sInputZipcode: any, sDeviceType: any, changeloc: any)
{
  var result = true;
  var sInput = sInputZipcode;
  localStorage.setItem("isCityEntered", "false");
  if (sDeviceType === "desk")
  {
    
    var lblErrorID = ($("#brandName").val().toLowerCase() === 'mr-handyman' || $("#brandName").val().toLowerCase() === 'five-star-painting' || $("#brandName").val().toLowerCase() === 'mr-rooter')?".main_zipcode_error" :"#main_zipcode_error";
    if (sInput.trim() === "")
    {
      $(lblErrorID).css('display', 'inline-block');
      $(lblErrorID).attr("aria-live", "polite");
      if ($("#countryCode").val() === 'us')
      {
        $(lblErrorID).text("ZIP Code cannot be blank");
        $(lblErrorID).attr("aria-label", "ZIP Code cannot be blank");
      } else
      {
        if ($("#conceptCode").val() === 'MRR')
        {
          $(lblErrorID).text("Postal Code/City Name cannot be blank");
          $(lblErrorID).attr("aria-label", "Postal Code/City name cannot be blank");
        } else
        {
          $(lblErrorID).text("Postal Code cannot be blank");
          $(lblErrorID).attr("aria-label", "Postal Code cannot be blank");
        }
      }
      if (changeloc == true)
      {
        $("#main_btn_zipcode_change_loc").addClass("m-4");
      }
      result = false;
    }
    else
    {
      const isUsZipCodeRegex = new RegExp(/^([0-9]{5})$/);
      let isCaPostalCodeRegex = new RegExp(/^([A-Za-z][0-9][A-Za-z][0-9][A-Za-z][0-9])$/);
      const mrrCaCodeRegex =   new RegExp(/^([A-Za-z][0-9][A-Za-z])$/);
      const caPostalCodeWithSpaceRegex = new RegExp(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);
      const threeCharCaCodeRegex = new RegExp(/^[A-Za-z]\d[A-Za-z]$/);
      const cityRegex = new RegExp(/^([a-zA-Z]+)$/);
      $(lblErrorID).attr("aria-label", "");
      $(lblErrorID).attr("aria-live", "off");

      if ($("#countryCode").val() === 'us') // US locations
      {
        if (isUsZipCodeRegex.test(sInput.trim())
          || ($("#conceptCode").val() === 'FSP' && (threeCharCaCodeRegex.test(sInput.trim()) || caPostalCodeWithSpaceRegex.test(sInput.trim()))))
        {
          $(lblErrorID).css('display', 'none');
        }
        else
        {
          $(lblErrorID).text("Invalid ZIP/Postal Code");
          $(lblErrorID).attr("aria-label", "Invalid ZIP/Postal Code");
          $(lblErrorID).css('display', 'inline-block');
          $(lblErrorID).attr("aria-live", "polite");
          result = false;
        }
      } else if ($("#countryCode").val() === 'ca')   // CA locations
      {
        if ($("#conceptCode").val() === 'MRR'){
            sInput = sInput.replace(/\s/g, "");
            if (mrrCaCodeRegex.test(sInput) || isCaPostalCodeRegex.test(sInput))
            {
              $('#main_mob_zipcode_error').css('display', 'none');
              localStorage.setItem("isCityEntered", "false");
              localStorage.setItem("locateCityname", '');
              result = true;
            } else if(cityRegex.test(sInput))
            {
              localStorage.setItem("isCityEntered", "true");
              localStorage.setItem("locateCityname", sInput);
              result = true;
            }else {
              $(lblErrorID).css('display', 'inline-block');
              $(lblErrorID).attr("aria-live", "polite");
              result = false;
              $(lblErrorID).text("Invalid ZIP/Postal Code");
              $(lblErrorID).attr("aria-label", "Invalid ZIP/Postal Code");
            }
            
        }
        else {
          if (croEnableForFindLocation() &&
            ($("#countryCode").val() === 'ca' || $("#conceptCode").val() === 'FSP')) {
            if(sInput.length > 3){
              isCaPostalCodeRegex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
            }else{
              isCaPostalCodeRegex = /^[A-Za-z]\d[A-Za-z]/; //3 char postal code
            }
          }
          if (isCaPostalCodeRegex.test(sInput.replace(/\s/g, "")))
          {
            $(lblErrorID).css('display', 'none');
          }
          else {
            $(lblErrorID).css('display', 'inline-block');
            $(lblErrorID).attr("aria-live", "polite");
            result = false;
            $(lblErrorID).text("Invalid ZIP/Postal Code");
            $(lblErrorID).attr("aria-label", "Invalid ZIP/Postal Code");
          }
        }
      }
    }
  }
  else if (sDeviceType === "mob")
  {
    if (sInput.trim() === "")
    {
      $('#main_mob_zipcode_error').css('display', 'inline-block');
      if ($("#countryCode").val() === 'us')
      {
        $("#main_mob_zipcode_error").text("ZIP Code cannot be blank");
      } else
      {
        if ($("#conceptCode").val() === 'MRR')
        {
          $("#main_mob_zipcode_error").text("Postal Code/City name cannot be blank");
        } else
        {
          $("#main_mob_zipcode_error").text("Postal Code cannot be blank");
        }

      }
      result = false;
    }
    else
    {
      var regex = new RegExp(/^([0-9]{5}|[A-Za-z][0-9][A-Za-z] ?[0-9][A-Za-z][0-9])$/);
      if (regex.test(sInput))
      {
        $('#main_mob_zipcode_error').css('display', 'none');
        localStorage.setItem("isCityEntered", "false");
        localStorage.setItem("locateCityname", '');
      } else
      {
        const new_regex = new RegExp(/^([A-Za-z][0-9][A-Za-z])$/);
        if (new_regex.test(sInput))
        {
          $('#main_mob_zipcode_error').css('display', 'none');
          localStorage.setItem("isCityEntered", "false");
          localStorage.setItem("locateCityname", '');
        } else
        {
          localStorage.setItem("isCityEntered", "true");
          localStorage.setItem("locateCityname", sInput);
        }
      }

    }

  }

  return result;
}

export function croEnableForFindLocation(){
    let checkCroleadEnable = false;
    let brandJsonItems = window.objOSGlobal.requestBodyValues?.brandJsonGlobalObj;
    if(brandJsonItems){
       checkCroleadEnable = brandJsonItems[0]?.enable_cro_enhance_lead_no_zip_match_findLocation || false ;
    }
    return checkCroleadEnable;
}