import { inputZipChecker } from "../util";
import { $ } from "../third-party-imports";

export function plgPreFilledData(){
    let postalCode = localStorage.getItem('zipcode');
    $("#Glass1").attr("checked", 'true').trigger("click");
    $('.plg-contact-information input[name="firstname"]').val('');
    $('.plg-contact-information input[name="lastname"]').val('');
    $('.plg-contact-information input[name="email"]').val('');
    $('.plg-contact-information input[name="inputPhnNumber"]').val('');
    $('.plg-contact-information input[name="address"]').val('');
    $('.plg-contact-information input[name="apartment"]').val('');
    $('.plg-contact-information input[name="city"]').val('');

    $('.plg-vechicle-information input[name="year"]').val('');
    $('.plg-vechicle-information input[name="make"]').val('');
    $('.plg-vechicle-information input[name="model"]').val('');
    $('.plg-vechicle-information #glasstype-error').hide();
    $('.plg-contact-screen .js-next-btn').html("Next "+$('.js-next-btn img')[0].outerHTML);
    $('.plg-contact-information input[name="postal"]').val(postalCode);
    window.objOSGlobal.requestBodyValues.ServiceText = "Auto Glass Appointment";
    window.objOSGlobal.requestBodyValues.FormOfPayment = "Personal (Cash, Check or Charge)";
    sessionStorage.setItem('glass-type-auto','Yes');
    sessionStorage.setItem('typeOfServiecPlg', 'dropoff1');
    inputZipChecker();
    window.objOSGlobal.requestBodyValues.IsLeadOnly = true;
}