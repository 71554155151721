import { initBlogArticlePage } from "./initBlogArticlePage";
import {getTitleDscForExpTips} from './getTitleDscForExpTips';
import { $ } from "../third-party-imports"

$(function ()
{
    let pageType = $('#pageType').val();
    if (pageType == "tips")
    {
        if (window.location.href.toLowerCase().indexOf("category") > 0 && window.g2Data !== null)
        {
            getTitleDscForExpTips();
            $('.hero-intro-blade').addClass('d-none');
            $('.text-container, .blog-section, .pagination-section').removeClass('d-none');
        }      

        initBlogArticlePage();
        $('.return-blog a').click(function (eve: any)
        {
            let linkHref = window.location.href.toLowerCase();
            eve.preventDefault();
            var returnlink = localStorage.getItem('current_context_url');
            if (returnlink === "" || returnlink === null)
            {
                let localDBA = "none";
                if(window.g2Data){ 
                    localDBA = window.g2Data.responseHeader.params.fq[0].toLowerCase().replace("custom_s_local_dba:","").replace("(","").replace(" or none)","");
                }
                else{
                    localDBA = $("#weblocationId").length ? linkHref.split('/')[3] : "none";
                }
                //const localDBA = window.g2Data.responseHeader.params.fq[0].replace("custom_s_local_dba:", "").toLowerCase();
                if (localDBA != "none")
                {
                    window.location.href = encodeURI(`${window.location.origin}/${localDBA}/blog`);
                }
                else
                {
                    window.location.href = encodeURI(`${window.location.origin}/blog`);
                }

            } else
            {
                window.location.replace(returnlink);
            }

        });
        }
});