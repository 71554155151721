import { gtmCustomEventLeadFlow } from '../util/gtm-custom-event-lead-flow';

export function loadAtStart() {
    let os_flow_dbaname: any = localStorage.getItem("OSDbaFullName");
    let os_flow_weblocationId: any = localStorage.getItem("OSWebLocationId");
    if (localStorage.getItem("localWebLocationId") && localStorage.getItem("dbaname")) {
        let leadObj: any
        let full_Dba: any = os_flow_dbaname !== null ? os_flow_dbaname : localStorage.getItem("dbaname");
        let locationWebLocationID = os_flow_weblocationId !== null ? os_flow_weblocationId : Number(localStorage.getItem("localWebLocationId"));
        if (sessionStorage.getItem("formType") && window.location.href.toLowerCase().includes('/confirmation/')) {
            
            leadObj = {
                event: 'custEv_LocalDataLayerOnLoad',
                locationID: locationWebLocationID,
                locationDBA: full_Dba,
                form_submission_type: sessionStorage.getItem("formType")
            }
        } else {
            leadObj = {
                event: 'custEv_LocalDataLayerOnLoad',
                locationID: locationWebLocationID,
                locationDBA: full_Dba
            }
        }
        if($('#weblocationId')[0] && $('#dbaName')[0]){
            gtmCustomEventLeadFlow(leadObj);
        }
        // below !$("#weblocationId") is added as we were having same web location id for 2 different id 'weblocationId' and 'local_weblocationId' 
        if (!$('#local_weblocationId')[0] && !$('#full_dbaName')[0] && !$("#weblocationId")[0]) {
            const webLocationId = `<input type="hidden" value= "${locationWebLocationID}" id="local_weblocationId">`;
            const dbaname = `<input type="hidden" value="${full_Dba}" id="full_dbaName">`

            $('body').prepend(webLocationId);
            $('body').prepend(dbaname);
        }
        gtmCustomEventLeadFlow(leadObj);
        
    } else {
        let leadObj: any;
        if (os_flow_dbaname !== null && os_flow_weblocationId !== null) {
            if (sessionStorage.getItem("formType") && window.location.href.toLowerCase().includes('/confirmation/')) {
                leadObj = {
                    event: 'custEv_LocalDataLayerOnLoad',
                    locationID: os_flow_weblocationId,
                    locationDBA: os_flow_dbaname,
                    form_submission_type: sessionStorage.getItem("formType")
                }
            } else {
                leadObj = {
                    event: 'custEv_LocalDataLayerOnLoad',
                    locationID: os_flow_weblocationId,
                    locationDBA: os_flow_dbaname
                }
            }
            if (!$('#local_weblocationId')[0] && !$('#full_dbaName')[0]) {
                const webLocationId = `<input type="hidden" value= "${os_flow_weblocationId}" id="local_weblocationId">`;
                const dbaname = `<input type="hidden" value="${os_flow_dbaname}" id="full_dbaName">`
                $('body').prepend(webLocationId);
                $('body').prepend(dbaname);
                gtmCustomEventLeadFlow(leadObj);
            }
        }
    }
    localStorage.removeItem("OSWebLocationId");
    localStorage.removeItem("OSDbaFullName");
}