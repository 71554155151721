export * from './inputCare';
export * from './attachSearchEvent';
export * from './attachNextPrevClickEvent';
export * from './stripURL';
export * from './stripTrailingSlash';
export * from './stripPageNumber';
// export * from './searchG2QueryParams';
export * from './titleCase';
export * from './Pagination';
//export * from '../ui/mobileSupport';
export * from './initPagination';
export * from './initBlogArticlePage';
export * from './getCurrentContext';
export * from './updateBlogResultText';
export * from './getAllBlogs';
// export * from './filterBlogFromList';
export * from './disableCurrentPageOnPaginationSection';
export * from './updateFooterLinks';
export * from './blogarticle';
export * from './blog'; 
export * from './blog-contact-form';