import { isNotProd } from ".";
import{commonAjaxCallfunctionHidingMenu} from "./commonAjaxCallfunctionHidingMenu";
export function hidingPhgMenuLink()
{
    let apiDomain = isNotProd() ? window.DEFINE_VALUE.JS_API_URL : "https://api.neighborly.com";
    let apiKey = isNotProd() ? window.DEFINE_VALUE.JS_API_KEY : "pneiweb-9pbaOywNKQj853D";
    let digitalorchestrations = isNotProd() ? window.DEFINE_VALUE.JS_DigitalOrchestrations_URL : "digitalorchestrations-api";
    let galleryapi = `${apiDomain}/${digitalorchestrations}/v1/public/photos/images`;
    // galleryapi += '?apikey=' + apiKey + '&weblocationId=' + $("#weblocationId").val();

    var weblocationId   = $("#weblocationId").val() ? $("#weblocationId").val() : localStorage.getItem("localWebLocationId");
    weblocationId = weblocationId || 0;
    var conceptId = $("#conceptId").val() || 0;
    var categoryID = 0;
    const galleryapiUrl = `${galleryapi}/${weblocationId}/${conceptId}/${categoryID}`;

    jQuery.expr[':'].contains = function(a:any, i:any, m:any) {
        return jQuery(a).text().toUpperCase()
            .indexOf(m[3].toUpperCase()) >= 0;
      };
    const headers = {
        'apikey': apiKey
    };
    commonAjaxCallfunctionHidingMenu(galleryapiUrl, headers, function (data: any)
    {
        var photos = data.photos;
        if (photos.length < 6)
        {   
            var a = document.querySelectorAll('.result-gallery');
            if(a){
                a[0]?.classList.add('hidden');
            }
            $('.dropdown-item:contains("photo gallery")').hide();
            $('.dropdown-link:contains("photo gallery")').hide();

            if($('#brandName').val()==='mr-handyman')
            {
                $('.dropdown-item:contains("see local work)').hide();
                $('.dropdown-link:contains("see local work")').hide();
            }
        }
        else
        {
            $('.dropdown-item:contains("photo gallery")').show();
            $('.dropdown-link:contains("photo gallery")').show();

            if($('#brandName').val()==='mr-handyman')
            {
                $('.dropdown-item:contains("see local work)').show();
                $('.dropdown-link:contains("see local work")').show();
            }
        }
    });
}
