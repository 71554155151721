import { GetReferenceDetails, addressInfoValMly, apiCall, categoryPage, checkZipLocalSite, contactInfoMly, defaultEnableCal, getStateFun, refreshScheduleData, servicePage, setAddressFormValueMly, startLoader } from "../..";
import { LeadConfirmation } from "../../services/LeadConfirmation";
import { $ } from "../../third-party-imports";
import { mollyMaidEstimate } from "../mollyMaidEstimate";
import { updateCommercialFlow, storeValueOneTimeAndRecurring } from "./cro-mly-one-time-recurring";
import { requestFormValidation } from "../../util/contact-request-form";
import { bookingApiPayload } from "../booking-api-payload";
import { getAboutHomeDetails } from "../getAboutHomeDetails";
import { aboutYourHomeScreen } from "../../util/updated-opus-online-scheduler-flow";
import { getDefaultHeaderLogo } from "../../util/get-default-header-logo";
import { servieModal } from "../../util/get-brands-json";
import { confimationScreen } from "../confirmation-secreen";
import { setLeadOnly } from "../setLeadOnly";
export function croOnlineModalFlow()
{
    $('.js-next-btn').off().on('click', async function (this: any)
    {
        if ($('#FinancingForm').length > 0)
        {
            requestFormValidation($(this));
        }
        let validate: any = $(".cro-contact-form").valid();
        if (!validate)
        {
            return;
        }
        let isLeadOnly = window.objOSGlobal.requestBodyValues.IsLeadOnly;
        let nextScreenId = $(this).parents('.tab-pane').next().attr("id");
        let nextId = nextScreenId ? nextScreenId.toLowerCase() : "";
        storeCroAllfieldsValue($(this));
        if (nextId)
        {
            if (nextId === "step2"){
                startLoader();
                storeValueOneTimeAndRecurring();
                storeCroglobalObjectValue();
                let changeZip = sessionStorage.getItem('changeZip');
                let selectedAboutHome = localStorage.getItem("selectedAboutHome");
                if (changeZip)
                {
                    checkZipLocalSite(nextId);
                    return;
                }
                if (isLeadOnly)
                {
                    setLeadOnly();
                    window.onbeforeunload = null;
                    if (selectedAboutHome === "onetime")
                    {
                        await mollyMaidEstimate();
                    }
                    LeadConfirmation();
                    return;
                }
                if (selectedAboutHome === "onetime")
                {
                    defaultEnableCal.enable = true;
                    await mollyMaidEstimate();
                }
                else if (selectedAboutHome === "recurring")
                {
                    defaultEnableCal.enable = true;
                    await refreshScheduleData(new Date().getFullYear(), new Date().getMonth() + 1);
                }
                onCloseBrowserandTab(nextId);
                
            }
            $('a[href="#' + nextId + '"]').parents("li").removeClass("disabled");
            $('a[href="#' + nextId + '"]').parents("li").addClass("active")
                .siblings("li").removeClass("active").addClass("complete");
            if (nextId !== "stepone")
            {
                $(".tab-pane.fieldset").removeClass('active');
                $('[href="#' + nextId + '"]').tab('show');
                $('#' + nextId).addClass('active');
            }
        }
        
    });
    $('.js-previous-btn').off().on('click', function (this: any)
    {
        let prevId = $(this).parents('.tab-pane').prev().attr("id");
        $('[href="#' + prevId + '"]').tab('show');
        $('a[href="#' + prevId + '"]').parents("li").addClass("active").removeClass("complete")
            .siblings("li").removeClass("active").addClass("disabled");
        window.onbeforeunload = null;
        if(document.querySelector('.mly-service-contact-form-screen')?.classList?.contains('active')){
            window.objOSGlobal.requestBodyValues.IsLeadOnly = window.objOSGlobal.requestBodyValues.calendarLead;
        }
        if (prevId === "steptwo")
        {
            $(".tab-pane.fieldset").removeClass("active");
            $(".zip-entry-screen").addClass('active');
        }
        else if (prevId === "stepone_change_location_local")
        {
            $('.zip-entry-screen').addClass('active');
            hidingHeadersEntryModal();
        } else
        {
            $('[href="#' + prevId + '"]').tab('show');
        }
    });

    const changeStepText = document.querySelector(".modal-step2 a");

    $(".confirmation-screen .scheduler-btn.next").off().on('click', function (e: any)
    {
        e.preventDefault();
        window.onbeforeunload = null;
        let currentScheduleId = $("button.calendar-btn.active-cal.active").attr("data-selectedDate");
        window.objOSGlobal.paramsUI.selectedDate = currentScheduleId;
        /* Commenting this code since Note field value is already being handled in
           mollyEstimate api response
        let estimate = sessionStorage.getItem('mollymaidprice');
        if(estimate){
            let note = window.objOSGlobal.requestBodyValues.Note;
            if(note !=''){
                window.objOSGlobal.requestBodyValues.Note = note + '\nMLY Estimate: ' + estimate;
            }
        }
        */
        LeadConfirmation();
    });

    // one time and recurring flow
    $('input[type="radio"]').off().on('click', function (this: any)
    {
        if ($(this).attr('id') == 'inputOneTime')
        {
            $(".frequency-group").addClass("checked");
            $(".saturday-selected").show();
            if (changeStepText)
            {
                changeStepText.textContent = "Schedule Appointment";
            }
        }
        if ($(this).attr('id') == 'inputRecurring')
        {
            $(".frequency-group").removeClass("checked");
            $(".saturday-selected").hide();
            if (changeStepText)
            {
                changeStepText.textContent = "Schedule Estimate";
            }
        }
        updateCommercialFlow();
    });

    $(document).ready(function ()
    {
        $(document).on('click', '.call-details .call-link.call-txt', function (e: any)
        {
            e.preventDefault();
            setLeadOnly();
            LeadConfirmation();
        });
    });
}


export function updateOpusCroOnlineFlow(stepNextId: any)
{
    let addresses = JSON.parse(localStorage.getItem("addressMly"));
    $(".tab-pane.fieldset").removeClass('active');
    $(".mly-service-contact-form-screen").addClass('active');
    getStateFun();
    hidingHeadersOSFlow();
    updateCroNumberAndDba();
    $("input[name='inputZipCode']").val(window.objOSGlobal.requestBodyValues.PostalCode);
    if (addresses !== null)
    {
        setAddressValueInContactInfo(addresses);
    }

}

export function updateCroNumberAndDba()
{
    $(".localized-header-item span a").text(' ' + window.objOSGlobal.paramsUI.brandPhoneNumber);
    $('.localized-header-item span a').attr('href', "tel:" + window.objOSGlobal.paramsUI.brandPhoneNumber);
    $(".localized-header-item .schedulerModalLabel").text(window.objOSGlobal.paramsUI.doingBusinessAs);
    $(".localized-progress").show();
}

function hidingHeadersOSFlow()
{
    $(".modal-header-one").show();
    $(".modal-header-two").hide();
}

function hidingHeadersEntryModal()
{
    $(".modal-header-one").hide();
    $(".modal-header-two").show();
}


function storeCroAllfieldsValue($button: any)
{
    var $current_step = $button.parents('.tab-pane');
    const $name_fields = $current_step.find('.form-control');
    $name_fields.each(function (i: any, $element: any)
    {
        const $field = $($element);
        let $name: any = $field.attr('name');
        if ($field.is(':visible'))
        {
            window.objOSGlobal.requestBodyValues[$name] = $field.val();
        }
    });
}

export function storeCroglobalObjectValue()
{
    let { inputFirstName, inputLastName, email, inputPhnNumber, inputAddress, inputAddress2, inputcity, inputstate, inputZipCode, squareFeet, bedrooms, bathrooms } = window.objOSGlobal.requestBodyValues;
    window.objOSGlobal.requestBodyValues.FirstName = inputFirstName;
    window.objOSGlobal.requestBodyValues.LastName = inputLastName;
    window.objOSGlobal.requestBodyValues.Email = email?.trim();
    window.objOSGlobal.requestBodyValues.Phone = inputPhnNumber;
    window.objOSGlobal.requestBodyValues.Address = inputAddress;
    window.objOSGlobal.requestBodyValues.Address2 = inputAddress2;
    window.objOSGlobal.requestBodyValues.City = inputcity;
    window.objOSGlobal.requestBodyValues.State = inputstate;
    window.objOSGlobal.requestBodyValues.PostalCode = inputZipCode;
    window.objOSGlobal.requestBodyValues.EstimateSquareFeet = squareFeet;
    window.objOSGlobal.requestBodyValues.EstimateBedrooms = bedrooms;
    window.objOSGlobal.requestBodyValues.EstimateBathrooms = bathrooms;
    var addressParamsVal: any = inputAddress + "&" + inputAddress2 + "&" + inputcity + "&" + inputstate + "&" + window.objOSGlobal.requestBodyValues.PostalCode;
    return addressParamsVal
}


export function generateCroLead()
{
    callBookingApiLead();
    window.onbeforeunload = null;
}

function onCloseBrowserandTab(nextId: any)
{
    if (nextId === "step2")
    {
        window.onbeforeunload = async function ()
        {
            window.onbeforeunload = null;
            await callBookingApiLead();
        }
    }
}

async function callBookingApiLead()
{
    setLeadOnly();
    let url = window.DEFINE_VALUE.bookingEndpoint + "?apikey=" + window.DEFINE_VALUE.JS_API_KEY;
    let payload: any = bookingApiPayload();
    await fetch(url, {
        method: "POST",
        keepalive: true,
        body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json"
        }
    });
}


function setAddressValueInContactInfo(addressObj: any)
{
    if (addressObj)
    {
        $(".contact-information-screen #inputAddress").val(addressObj.address);
        $(".contact-information-screen #inputAddress2").val(addressObj.apartment);
        $(".contact-information-screen #inputcity").val(addressObj.city);
        $('.contact-information-screen option[value=' + addressObj.state + ']').attr('selected', 'selected');
        $(".contact-information-screen #inputZipCode").val(addressObj.zipCode);
    }
}

export function commercialMlycroFlow()
{
    $(".js-next-btn").click(async function (this: any)
    {
        document.getElementById("js-schedulerDialog").scroll(0, 0);
        let nextId = $(this).parents(".tab-pane").next().attr("id");
        if (nextId == "stepfour")
        {
            var pageType = $('#pageType').val();
            if (pageType == 'servicePage')
            {
                $('.scheduler-modal .modal-content').removeClass('banner-wizard');
                servicePage();
                return;
            }
            if (pageType == 'categoryPage')
            {
                $('.scheduler-modal .modal-content').removeClass('banner-wizard');
                categoryPage();
                return;
            }
            $('.scheduler-modal .modal-content').addClass('banner-wizard');
            return;
        }
        else if (nextId == "step3")
        {
            if (!$("#aboutHome").valid())
            {
                return false;
            }
            let nextImg = $('.mly-service-contact-form-screen .js-next-btn').find('img');
            var addresses = JSON.parse(localStorage.getItem("addressMly"));
            apiCall.error = false;
            if (addresses)
            {
                setAddressFormValueMly(addresses);
            }
            let frequency = $("input:radio[name=chkFrequesy]:checked").val();
            localStorage.setItem('selectedAboutHome', frequency);
            $(".mly-service-contact-form-screen #inputZipCode").val(localStorage.getItem('zipcode'));
            window.objOSGlobal.requestBodyValues.ScheduleID = '';
            sessionStorage.removeItem('mollymaidprice');
            sessionStorage.removeItem('estimateSaturdayPrice');
            window.objOSGlobal.requestBodyValues.IsLeadOnly = true;
            $('.tab-pane.fieldset').removeClass('confirmation-screen');
            $('.progressbar li').css('width', 'calc(100%/2)');
            $('#schedulerWizard .scheduler-slide .progressbar').addClass('hide-estimate-flow');
            $('.mly-service-contact-form-screen').addClass('confirmation-screen');
            if ($('.scheduler-slide .tab-pane.fieldset').hasClass('confirmation-screen'))
            {
                $('.confirmation-screen .js-next-btn').html('Confirm Your Estimate Appointment').append(nextImg);
                $('.selected-date-mobile').addClass('recurring-selected-time');
            }
            window.objOSGlobal.requestBodyValues.ServiceText = "Lead Only";
            $('[href="#' + nextId + '"]').tab("show");
            GetReferenceDetails();
            getAboutHomeDetails();
            aboutYourHomeScreen();
        }
        else if (nextId == "step4")
        {
            if (!$("#contactusformMly").valid())
            {
                return;
            }

            $('#no-service-close-btn').val('close');
            $('#no-service-close-btn').removeClass('try-again');
            if ($('.mly-service-contact-form-screen').hasClass('confirmation-screen'))
            {
                $('.mly-service-contact-form-screen').addClass('active');
            }
            contactInfoMly();
            addressInfoValMly();
            confimationScreen();
        } else
        {
            $('[href="#' + nextId + '"]').tab("show");
        }
    });
    $(".js-previous-btn").click(function (this: any)
    {
        getDefaultHeaderLogo();
        let prevId = $(this).parents(".tab-pane").prev().attr("id");
        if (prevId == "stepone")
        {
            $('.scheduler-modal .modal-content').addClass('banner-wizard');
        }
        if (prevId == "step1")
        {
            $('.progress, .progressbar').hide();
            $('.scheduler-modal .modal-content').addClass('banner-wizard');
            if (sessionStorage.getItem('serCatBack'))
            {
                $('.tab-pane.fieldset').removeClass('active');
                $('.zip-entry-screen').addClass('active');
                sessionStorage.removeItem('serCatBack')
                sessionStorage.setItem('servicePageClick', 'true');
            } else if (sessionStorage.getItem('serCatBtnBack'))
            {
                $('.tab-pane.fieldset').removeClass('active');
                $('.service-list-screen').addClass('active');
            } else
            {
                $('.tab-pane.fieldset').removeClass('active');
                if (servieModal.enable)
                {
                    $('.zip-entry-screen').addClass('active');
                } else
                {
                    $('.service-search-screen').addClass('active');
                }
            }
            return;
        }
        if (prevId == "step3")
        {
            defaultEnableCal.enable = true;
            if (window.objOSGlobal.requestBodyValues.modifier == 'Commercial')
            {
                $('.scheduler-modal .modal-content').addClass('banner-wizard');
                if (sessionStorage.getItem('serCatBack'))
                {
                    $('.tab-pane.fieldset').removeClass('active');
                    $('.zip-entry-screen').addClass('active');
                    sessionStorage.removeItem('serCatBack')
                    sessionStorage.setItem('servicePageClick', 'true');
                } else if (sessionStorage.getItem('serCatBtnBack'))
                {
                    $('.tab-pane.fieldset').removeClass('active');
                    $('.service-list-screen').addClass('active');
                } else
                {
                    $('.tab-pane.fieldset').removeClass('active');
                    $('.service-search-screen').addClass('active');
                }
            }
        }
        if (prevId == "step4")
        {
            $('[href="#step4"]').tab("show");
        }
        else
        {
            $('[href="#' + prevId + '"]').tab("show");
        }
        return false;
    });
    $('a[data-toggle="tab"]').on("shown.bs.tab", function (e: any)
    {
        let step = $(e.target).data("step");
        let percent: any = ((parseInt(step) / 3) * 100).toFixed(2);
        let isLeadOnly = true;
        switch (step)
        {
            case 1:
                percent = 0;
                $(".progress-bar").css({ width: percent + "%" });
                break;
            case 2:
                percent = 5.2;
                $(".progress-bar").css({ width: percent + "%" });
                break;
            case 3:
                percent = isLeadOnly ? 100 : 52;
                $(".progress-bar").css({ width: percent + "%" });
                break
            case 4:
                percent = 100;
                $('.progress-bar').css({ width: percent + '%' });
                break;
            case 5:
                percent = 100;
                $('.progress-bar').css({ width: percent + '%' });
                break;
        }
        $('.progress-bar-info').text("" + percent + "% complete");
    });
    window.objOSGlobal.requestBodyValues.IsEstimate = true;
    $("input[name='chkFrequesy']").click(function (this: any)
    {
        if ($("#inputOneTime").is(":checked"))
        {
            $("#js-one-time-data").show();
            $("#js-recurring-data").hide();
            var idVal = $(this).attr("id");
            window.objOSGlobal.requestBodyValues.Note = '';
            window.objOSGlobal.requestBodyValues.Note = $("label[for='" + idVal + "']").text();
            localStorage.setItem('selectedAboutHome', "onetime");
            window.objOSGlobal.requestBodyValues.IsEstimate = false;
            sessionStorage.setItem('MlyCleaning', 'Yes');
            sessionStorage.removeItem('MlyFrequency');
        }
        if ($("#inputRecurring").is(":checked"))
        {
            $("#js-recurring-data").show();
            $("#js-one-time-data").hide();
            var idVal = $(this).attr("id");
            window.objOSGlobal.requestBodyValues.Note = '';
            window.objOSGlobal.requestBodyValues.Note = $("label[for='" + idVal + "']").text();
            localStorage.setItem('selectedAboutHome', "recurring");
            $('.scheduler-slide .progressbar li.services-list').show();
            window.objOSGlobal.requestBodyValues.IsEstimate = true;
            sessionStorage.setItem('MlyFrequency', 'weekly');
            sessionStorage.removeItem('MlyCleaning');
        }
    });
    $("input[name='chkCleaning']").click(function (this: any)
    {
        var idVal = $(this).attr("id");
        sessionStorage.setItem('MlyCleaning', $("label[for='" + idVal + "']").text().replace(/\s+/g, ''));
    });
}