import {$} from "../third-party-imports";
import { showOfferAttachedText, startLoader, stopLoader } from "../util";
import { getBrandDetailsFromBrandJson } from "../util/util";
import { croConfirmation } from "./cro-os-flow/cro-mly-one-time-recurring";
export async function leadConfirmationCall(){
    var MlyHomeDetails = sessionStorage.getItem("MlyHomeDetails");
    var firstName = sessionStorage.getItem("CFirstName");
    var lastName = sessionStorage.getItem("CLastName");
    var jobDate = sessionStorage.getItem("CJobDate");
    var Time = sessionStorage.getItem("CTime");
    var serviceRequest = sessionStorage.getItem("CServiceRequest") ?? '';
    var email = sessionStorage.getItem("CEmail");
    var phone = sessionStorage.getItem("CPhone");
    var street = sessionStorage.getItem("CAddress");
    var street2 = sessionStorage.getItem("CAddress2");
    var city = sessionStorage.getItem("CCity");
    var state = sessionStorage.getItem("CState");
    var pincode = sessionStorage.getItem("CPostalCode");
    var modifier = sessionStorage.getItem('ServiceModifierValue');
    var ServiceType = sessionStorage.getItem('CServiceType');
    var BusinessName = sessionStorage.getItem('CBusinessName');
    var guyServiceType = sessionStorage.getItem('guyServiceType');
    var guyServiceRequest = sessionStorage.getItem('guyServiceRequest');
    var fullname = firstName + " " + lastName;
    var addressObj = street2 != "" ? street + ' ' + street2 : street;
    var fulladdress = addressObj + '<br/>' + city + ', ' + state + ' ' + pincode;
    var estimateMolly = sessionStorage.getItem('mollyEstimate');
    var mraDiagnoseFee = sessionStorage.getItem('mra-diagnose-fee');
    var dt = $('<dt>'); var dt1 = $('<dt>'); var dt2 = $('<dt>'); var dt3 = $('<dt>'); var dt4 = $('<dt>'); var dt5 = $('<dt>'); var dt6 = $('<dt>'); var dt7 = $('<dt>'); var dt8 = $('<dt>'); var dt9 = $('<dt>'); var dt10 = $('<dt>'); var dt11 = $('<dt>');var dt12 = $('<dt>');var dt13 = $('<dt>');var dt14 = $('<dt>');var dt15 = $('<dt>');var dt16 = $('<dt>');
    var dd = $('<dd>'); var dd1 = $('<dd>'); var dd2 = $('<dd>'); var dd3 = $('<dd>'); var dd4 = $('<dd>'); var dd5 = $('<dd>'); var dd6 = $('<dd>'); var dd7 = $('<dd>'); var dd8 = $('<dd>'); var dd9 = $('<dd>'); var dd10 = $('<dd>'); var dd11 = $('<dd>');var dd12 = $('<dd>');var dd13 = $('<dd>');var dd14 = $('<dd>');var dd15 = $('<dd>');var dd16 = $('<dd>');
    if (sessionStorage.getItem("CLeadOnly") != 'true') {
        $(dt).html('Date:');
        $(dd).html(jobDate);
        $(dt1).html('Time:');
        $(dd1).html(Time);
        if($("#confirmation-title-schedule").val() != null && $("#confirmation-title-schedule").val() != "")
        $(".confirmation-data .primary-title").text($("#confirmation-title-schedule").val() as any);
        else
        $(".confirmation-data .primary-title").addClass("d-none");
        if($("#confirmation-subtext-schedule").val() != null && $("#confirmation-subtext-schedule").val() != "")
        $(".confirmation-data .sub-title").text($("#confirmation-subtext-schedule").val() as any);
        else
        $(".confirmation-data .sub-title").addClass("d-none");
    }
    else{
        if($("#confirmation-title-lead").val() != null && $("#confirmation-title-lead").val() != "")
        $(".confirmation-data .primary-title").text($("#confirmation-title-lead").val() as any);
        else
        $(".confirmation-data .primary-title").addClass("d-none");
        if($("#confirmation-subtext-lead").val() != null && $("#confirmation-subtext-lead").val() != "")
        $(".confirmation-data .sub-title").text($("#confirmation-subtext-lead").val() as any);
        else
        $(".confirmation-data .sub-title").addClass("d-none");
        
    }
    if($('#conceptCode').val() == "MLY" || $('#conceptCode').val()=='FSP' || sessionStorage.getItem("ServiceModalEnable")) {
        if($('#conceptCode').val() != "MLY" && $('#conceptCode').val() != "GUY" && sessionStorage.getItem("CLeadOnly") == 'true'){
            $(".confirmation-page .confirmation-col.appointment").addClass("d-none");
        }
        else{
            $(".confirmation-page .confirmation-col.appointment").removeClass("d-none");
        }
    }else{
        $(dt2).html('Service Request:');
        $(dd2).html(serviceRequest);
    }
    if($('#conceptCode').val() == "MLY"){
        if(localStorage.getItem("selectedAboutHome") == "onetime"){
            $(dt7).html('Frequency:');
            $(dd7).html('One-time Cleaning');
        }
        else{
            $(dt7).html('Frequency:');
            $(dd7).html('Recurring Cleaning');
        }
        if(estimateMolly){
            $(dt9).html('Estimate:');
            $(dd9).html(estimateMolly);
        }
        $(dd7).attr('id','cleaning-type');
    }
    if($('#conceptCode').val() == "MLY"){
        if(modifier =='Commercial'){
            $(dt8).html('Office Details:');
            $(dd8).html(MlyHomeDetails);
        }else{
            $(dt8).html('Home Details:');
            $(dd8).html(MlyHomeDetails);
        }
        
    }
    if(sessionStorage.getItem("isSchedulerProEnabled")==='true'){
        let selectedServiceType = sessionStorage.getItem("selectedServiceType");
        let selectedCategoryName = sessionStorage.getItem("selectedCategoryName");
        let selectedServiceName = sessionStorage.getItem("selectedServiceName");
        if(selectedServiceType && $("#brandName").val().toLowerCase() != 'mr-handyman'){
            $(dt15).html('Service Request:');
            $(dd15).html(selectedServiceType);
        }
        if(selectedCategoryName || selectedServiceName){
            $(dt16).html('Service Description:');
            $(dd16).html(`${selectedCategoryName ?? ''}${selectedCategoryName && selectedServiceName ? ', ' : ''}${selectedServiceName ?? ''}`);
        }
    }
    $(dt3).html('Name:');
    $(dd3).html(fullname);
    $(dt4).html('Email:');
    $(dd4).html(email);
    $(dt5).html('Phone:');
    $(dd5).html(phone);
    $(dt6).html('Service Address:');
    $(dd6).html(fulladdress);
    $(dt10).html('Service Type:');
    if(sessionStorage.getItem('isSchedulerProEnabled')==='true' && $("#brandName")?.val()?.toLowerCase() === 'mr-handyman'){
        $(dd10).html(sessionStorage.getItem("selectedServiceType"));
    }
    else{
        $(dd10).html(ServiceType);
    }
    if(BusinessName) {
        $(dt11).html('Business Name:');
        $(dd11).html(BusinessName);
    }
    if($('#conceptCode').val() == "MRA"){
        $(dt2).html('Service Request:');
        $(dd2).html(serviceRequest);         
        //mra diagnose fee on confirmation page
        if(mraDiagnoseFee !=""){
            $(dt12).html('Diagnostic Fee:');
            $(dd12).html('Our in-home service professionals will look at your appliance, diagnose the problem, and give you an exact quote. If you choose not to have the repair done, you pay only the <strong>$' + mraDiagnoseFee + '</strong>.' +' diagnostic fee');
        } 
    }
    if($('#conceptCode').val() == "GUY"){
        $(dt2).html('Service Type:');
        $(dd2).html(guyServiceType);
        $(dt13).html('Service Request:');
        $(dd13).html(guyServiceRequest);
    }
    $('.confirmation-data .appointment .text-sm').append(dt).append(dd);
    $('.confirmation-data .appointment .text-sm').append(dt1).append(dd1);
    $('.confirmation-data .appointment .text-sm').append(dt2).append(dd2);
    $('.confirmation-data .appointment .text-sm').append(dt13).append(dd13);
    $('.confirmation-data .appointment .text-sm').append(dt7).append(dd7);
    $('.confirmation-data .appointment .text-sm').append(dt8).append(dd8);
    $('.confirmation-data .contact .text-sm').append(dt3).append(dd3);
    $('.confirmation-data .contact .text-sm').append(dt4).append(dd4);
    $('.confirmation-data .contact .text-sm').append(dt5).append(dd5);
    $('.confirmation-data .contact .text-sm').append(dt14).append(dd14);
    $('.confirmation-data .contact .text-sm').append(dt6).append(dd6);
    $('.confirmation-data .appointment .text-sm').append(dt9).append(dd9);
    $('.confirmation-data .appointment .text-sm').append(dt15).append(dd15);
    $('.confirmation-data .appointment .text-sm').append(dt16).append(dd16);
    if(localStorage.getItem("estIsLeadOnly") === 'false'){
        $('.confirmation-data .appointment .text-sm').append(dt12).append(dd12);
    } else {
        $('.confirmation-data .contact .text-sm').append(dt12).append(dd12);
    }
    if($("#brandName").val() === 'mr-handyman' || $("#brandName").val() === 'my-handyman') {
        if(sessionStorage.getItem("CLeadOnly") != 'true') {
          if(ServiceType) {
              $('.confirmation-data .appointment .text-sm').append(dt10).append(dd10);
              }
              if(BusinessName) {
              $('.confirmation-data .appointment .text-sm').append(dt11).append(dd11); 
              }
        }else {
          if(ServiceType) {
              $('.confirmation-data .contact .text-sm').append(dt10).append(dd10);
          }
          if(BusinessName) {
              $('.confirmation-data .contact .text-sm').append(dt11).append(dd11); 
          }
        }
      }

    //USER STORY 76501
    if($('#conceptCode').val() == "MLY") {
        var frequency = localStorage.getItem("selectedAboutHome");
        
        const result = await getBrandDetailsFromBrandJson();
        if (result.length > 0) {
            var items = $.grep(result, function (e:any) { return e.code === $('#conceptCode').val() });
            
            if(items.length) {
                
                var title_Lead = frequency == "recurring" ? items[0].confirmation_title_lead_recurring : items[0].confirmation_title_lead_onetime;
                var subtitle_Lead = frequency == "recurring" ? items[0].confirmation_subtext_lead_recurring : items[0].confirmation_subtext_lead_onetime;
                var title_schedule = frequency == "recurring" ? items[0].confirmation_title_schedule_recurring : items[0].confirmation_title_schedule_onetime;
                var subtitle_schedule = frequency == "recurring" ? items[0].confirmation_subtext_schedule_recurring : items[0].confirmation_subtext_schedule_onetime;

                if (sessionStorage.getItem("CLeadOnly") != 'true') {
                    $('.confirmation-banner .primary-title').text(title_schedule);
                    $('.confirmation-banner .sub-title').text(subtitle_schedule);
                }
                else{
                    $('.confirmation-banner .primary-title').text(title_Lead);
                    $('.confirmation-banner .sub-title').text(subtitle_Lead);
                }
                croConfirmation();
            }
        }
    }
    const OfferText=sessionStorage.getItem('OfferText');
    if(OfferText){
        $('#lead-offer-title').removeClass('d-none');
        $('.lead-offer-text').text(OfferText);
    }
    const apiConfig = sessionStorage.getItem('fakeSubmitPayload')
    if(apiConfig){
        console.log("API Config DATA-> ",JSON.parse(apiConfig));
    }
    if($('#conceptCode').val()==="FSP"){
        startLoader();
        showOfferAttachedText();
        loadContactInfo();
    }
}

function loadContactInfo(): void {
    if (!window.location.href.includes(`/confirmation`)) return;

    // Hide placeholder content
    $(".placeholder-content").hide();

    // Extract session data
    const serviceType = sessionStorage.getItem("serviceType") || "";
    const serviceProperty = sessionStorage.getItem("serviceProperty") || "";
    const contactInfoIsMobile = sessionStorage.getItem("contactInformationIsMobile") !== "";
    const firstName = sessionStorage.getItem("CFirstName") || "";
    const lastName = sessionStorage.getItem("CLastName") || "";
    const email = sessionStorage.getItem("CEmail") || "";    
    const phone = sessionStorage.getItem("CPhone") || "";
    const address = sessionStorage.getItem("CAddress") || "";
    const address2 = sessionStorage.getItem("CAddress2") || "";
    const city = sessionStorage.getItem("CCity") || "";
    const state = sessionStorage.getItem("CState") || "";
    const postalCode = sessionStorage.getItem("CPostalCode") || "";

    // Populate service info
    $(".appointment-info-box.service-info")
        .show()
        .find(".service-info-service").html(`Painting Service: ${serviceType}`)
        .end().find(".service-info-type").html(`Property Type: ${serviceProperty}`);

    // Populate contact info
    $(".appointment-info-box.contact-box-info")
        .show()
        .find(".contact-info-name").html(`${firstName || ""} ${lastName || ""}`)
        .end().find(".contact-info-email").html(email || "")
        .end().find(".contact-info-mobile").html(`${phone || ""} ${contactInfoIsMobile ? "(Mobile)" : ""}`);

    // Populate address info
    $(".appointment-info-box.address-info")
        .show()
        .find(".appointment-info-content").html(`
            ${address || ""}
            ${address2 ? `<br/>${address2}` : ""}
            <br/>${city || ""}, ${state || ""} ${postalCode || ""}
        `);

    if (sessionStorage.getItem('CLeadOnly')!=="true") updateAppointment();
    stopLoader();
}

// Update date and time info
function updateAppointment(): void {
    const appointmentBox = $(".appointment-info-box.date-and-time-info");
    const date = sessionStorage.getItem("CJobDate") || "";
    const time = sessionStorage.getItem("CTime") || "";

    if (date && time) {
        appointmentBox.show()
            .find(".date-info").html(date)
            .end().find(".time-info").html(time);
    }
}
