import { gtmEventBrandWise } from "../scheduler/gtm-event-brand";
import { gtmCustomEventLeadFlow } from "./gtm-custom-event-lead-flow";

export function requestEstimate(submitType:string, formType:string, formStatus:string, formDescription:string){
    let brandWise = $("#conceptCode").val().toUpperCase();
    let altWebLocationId = localStorage.getItem('weblocationId') ? localStorage.getItem('weblocationId'):'';
    if(gtmEventBrandWise(brandWise)){
        let custEvObj = {
            event:'request_estimate',
            locationID: window.objOSGlobal.requestBodyValues.WebLocationId ? window.objOSGlobal.requestBodyValues.WebLocationId : altWebLocationId,
            locationDBA:window.objOSGlobal.paramsUI.doingBusinessAs,
            submitType: submitType,
            formType: formType,
            formDescription: formDescription,
            formStatus: formStatus
        }
        gtmCustomEventLeadFlow(custEvObj);
        sessionStorage.setItem('request_estimate', JSON.stringify(custEvObj));
    }
}