import { $ } from "./third-party-imports";

document.addEventListener("DOMContentLoaded", function(){
    const brand = $('body').attr('class');
    if ($('body').attr('id'))
    {
        if ($('body').attr('id').indexOf('local') != -1)
        {
            localStorage[brand] = $(location).attr('href');
        }
    }
    if (!$('body').attr('id') && $('body').attr('class'))
    {
        Object.keys(localStorage).forEach(function (key)
        {
            if ($('body').attr('class') == key)
            {
                const referrer = localStorage.getItem(key);

                $('header#site-header').load('' + referrer + ' header');
                $('footer').load('' + referrer + ' footer');
            }
        });
    }
})
