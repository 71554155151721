import { getAllBlogs } from "./getAllBlogs";
/**
 * Build Search G2 Query Params
 */

export function searchG2QueryParams(key: any, value: any)
{
    const domainName = window.location.origin.toLowerCase();   
    const linkHref = window.location.href.toLowerCase();
    const domainType = window.DEFINE_VALUE.Domain_Type;
    const g2Collection = "https://searchg2.crownpeak.net/"+window.DEFINE_VALUE.Search_G2_URL+"/select/";
    let g2CollectionURL;
    let brandQuery = "";
    if (domainType != "digital")
    {
        const langauge = window.location.href.split('/')[3] === "ca" ? "ca" : "us";
        const localDBA = window.location.href.split('/')[3] === "ca" ? linkHref.split("/")[6] : linkHref.split("/")[5];
        const brandName = window.location.href.split('/')[3] === "ca" ? linkHref.split("/")[4] : linkHref.split("/")[3];
        let localCount = window.location.href.split('/')[3] === "ca" ? 7 : 6;
        if (linkHref.split("/")[localCount] != undefined) {
            if (linkHref.split("/")[localCount].indexOf("expert-tips") != -1) {
            brandQuery = `&fq=custom_s_local_dba:(${​localDBA})`;
            }
        }
        else{        
            brandQuery = `&fq=custom_s_local_dba:none`;
        }
        let g2Brand = `?q=custom_s_brand:${brandName}${brandQuery}`;
        let start = 0;
        let otherParams = `&start=${start}&rows=1000&wt=json&indent=true&sort=custom_dt_created desc`;
        let filteredParams = "&fl=custom_s_heading,custom_s_article_summary_Image_url,custom_t_content_summary_Image_url_alt,custom_t_content_summary_Desc,custom_s_local_url,custom_s_context_url";
        if (key && value)
        {
            g2CollectionURL = g2Collection + g2Brand + '&fq=' + key + ':' + value + '&fq=custom_s_local_url:https*&fq=custom_s_status:Active&fq=custom_s_type:blog&fq=custom_s_country:'+langauge+'' + otherParams + filteredParams;
        
        } else
        {
            g2CollectionURL = g2Collection + g2Brand + '&fq=*&fq=custom_s_local_url:https*&fq=custom_s_status:Active&fq=custom_s_type:blog&fq=custom_s_country:'+langauge+'' + otherParams + filteredParams;
        }
    }
    else{
        let langauge = "";
        let localDBA = "";
        let brandName = "";  
        if(window.g2Data){ 
            langauge = window.g2Data.responseHeader.params.fq[1].replace("custom_s_country:","");
            localDBA = window.g2Data.responseHeader.params.fq[0].toLowerCase().replace("custom_s_local_dba:","").replace("(","").replace(" or none)","");
            brandName = window.g2Data.responseHeader.params.q.replace("custom_s_brand:","");
        }
        else{
            langauge = $("#countryCode").length ? $("#countryCode").val().toString() : (domainName.indexOf(".ca") == -1 ? (domainName.indexOf("-ca") != -1 ? "ca" : "us") : "ca");
            localDBA = $("#weblocationId").length ? linkHref.split('/')[3] : "none";
            brandName = $("#brandName").length ? $("#brandName").val().toString() : "";
        }
        brandQuery = `&fq=custom_s_local_dba:(${​localDBA})`;
        let g2Brand = `?q=custom_s_brand:${brandName}${brandQuery}`;
        let start = 0;
        let otherParams = `&start=${start}&rows=1000&wt=json&indent=true&sort=custom_dt_created desc`;
        let filteredParams = "&fl=custom_s_heading,custom_s_article_summary_Image_url,custom_t_content_summary_Image_url_alt,custom_t_content_summary_Desc,custom_s_local_url,custom_s_context_url";
        if (key && value)
        {
            g2CollectionURL = g2Collection + g2Brand + '&fq=' + key + ':' + value + '&fq=custom_s_local_url:https*&fq=custom_s_status:Active&fq=custom_s_type:blog&fq=custom_s_country:'+langauge+'' + otherParams + filteredParams;
         
        } else
        {
            g2CollectionURL = g2Collection + g2Brand + '&fq=*&fq=custom_s_local_url:https*&fq=custom_s_status:Active&fq=custom_s_type:blog&fq=custom_s_country:'+langauge+'' +  otherParams + filteredParams;
        }
    }
    // Get All Blogs
    getAllBlogs(g2CollectionURL);
}