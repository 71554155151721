import { $ } from "../third-party-imports";
import {updateGallery} from './updateGallery';
import {photoObject} from './lightbox-gallery';

export function loadGallery(setIDs:any, setClickAttr:any) {
	let galleryComponent:any =  document.getElementsByClassName('result-gallery');
    let gallery:any =  document.getElementById('photo-gallery');
	if(gallery != null || galleryComponent.length > 0) {
		let $slider = document.getElementById('jsCarousel');
		// using this for swipe left and right functionality in touch devices
		if (window.matchMedia('(max-width: 767px)').matches) {
			if($('#hammer-script').length>0){
				let hammer = new Hammer($slider);
				hammer.on("swipeleft swiperight", e => {
					e.preventDefault();
					if (e.type == 'swipeleft') {				
						if(photoObject.isTabbedGallery && photoObject.current_image == photoObject.last_image) {
							photoObject.current_image = photoObject.first_image;
						}
						else{ 
							photoObject.current_image++;
						}

						if (photoObject.current_image === photoObject.counter + 1) {
							photoObject.current_image = 1;
						}
					}
					if (e.type == 'swiperight') {
						if(photoObject.isTabbedGallery && photoObject.current_image == photoObject.first_image) {
							photoObject.current_image = photoObject.last_image;
						}
						else{
							photoObject.current_image--;
						}

						if (photoObject.current_image === 0) {
							photoObject.current_image = photoObject.counter;
						}
					}
					photoObject.selector = $('[data-image-id="' + photoObject.current_image + '"]');
					updateGallery(photoObject.selector);
				});
			}
			
		}

		if (setIDs == true) {
			$('[data-image-id]').each(function() {
				photoObject.counter++;
				$(this).attr('data-image-id', photoObject.counter);
			});
		}
		$(setClickAttr).on('click', function() {
			updateGallery($(this));
		});
	}
}