import { $ } from "../third-party-imports";
import { apiCall,erasePreFilledDataOnClose, fullAddressRoundRobinApi,getFullAttributeApi,mrApplianceBrandList,mrh_OptOsFlow,refreshFSPCalendar,startLoader,stopLoader, stopLoaderWithDelay } from "..";
// import {getDisplayServicesList} from '../services/getDisplayServicesList';
import { commercialFlow } from "../scheduler/commercialFlow";
import { gtmCustomEventLeadFlow } from "./gtm-custom-event-lead-flow";
import { gtmEventBrandWise } from "../scheduler/gtm-event-brand";
import { MraProgressBar } from "./mra-progress-bar";
import { plgPreFilledData } from "../scheduler/plg-prefilled-data";
import { guyPreFilledData } from "../scheduler/guy-os-flow/guy-pre-filldata-remove";
import { fspPreFilledData } from "../scheduler/fsp-os-flow/fsp-pre-filldata-remove";
import { removePreFilledData } from "../scheduler/common-os/remove-prefilled-data";
import { commonProgressBar } from "../scheduler/common-os/common-progress-bar";
import { asvPreFilledData } from "../scheduler/asv-os-flow/asv-pre-filldata-remove";
import { removeClassForUpdateFlow, updatedOpusOnlineSchedulerFlow } from "./updated-opus-online-scheduler-flow";
import { updateOpusCroOnlineFlow } from "../scheduler/cro-os-flow/cro-os-flow";
import { getLocalDynamicMenu } from "./get-service-list";
import { updateProgressBar } from "../scheduler/initialCallFunction";
export function FspSchedulerFlow(postal:any){
startLoader();
fullAddressRoundRobinApi(postal,false,()=>{
    // getDisplayServicesList(postal,4,function(result:any){
    // if(result.length!=0){
    //         window.objOSGlobal.requestBodyValues.FranchiseId = result[0].franchiseId+'';
            localStorage.setItem('estfranchiseId', window.objOSGlobal.requestBodyValues.FranchiseId);
            getFullAttributeApi(window.objOSGlobal.requestBodyValues.WebLocationId,()=>{
                let nextId:any = $('.service-search-screen').next().attr("id");
                if($("#conceptCode").val() != "ASV" && $("#brandName").val() != "molly-maid" && $("#brandName").val() != "mr-appliance" && $("#brandName").val() != "portland-glass"
                && $("#brandName").val() != "mr-handyman" && $("#brandName").val() != "my-handyman" && $("#brandName").val() != "grounds-guys" && $("#conceptCode").val() != "FSP" && $("#conceptCode").val() != "MRR") {
                    if(!window.objOSGlobal.requestBodyValues.IsLeadOnly){
                        $(".tab-pane.fieldset").removeClass("active");
                        refreshFSPCalendar(new Date().getFullYear(), new Date().getMonth() + 1);
                        $('[href="#' + nextId + '"]').tab('show');
                        $('#'+nextId).addClass('active');
                        $('.nav-wizard.progressbar').removeClass('hide-schedule');
                        //stopLoader();
                    }else{
                        $('.tab-pane.fieldset').removeClass('active');
                        $('.nav-wizard.progressbar').addClass('hide-schedule');
                        $('.contact-information-screen').addClass('active');
                        $('.progress-bar').css({ width:'3.2%' });
                        stopLoader();
                    }
                    if (nextId == "step1") {
                        $('.scheduler-modal .modal-content').addClass('banner-wizard');
                    }else{
                        $('.scheduler-modal .modal-content').removeClass('banner-wizard');
                    }
            }else if($("#conceptCode").val().toUpperCase() =='MLY'){
                let checkModifier = window.location.href.includes('commercial');
                if(checkModifier){
                    removeClassForUpdateFlow();
                    updatedOpusOnlineSchedulerFlow(nextId);
                }else{
                    updateOpusCroOnlineFlow(nextId);
                }
                
            }else if($("#brandName").val() ==='mr-handyman' || $("#brandName").val() === "my-handyman"){
                mrh_OptOsFlow(nextId);
            }else if($("#brandName").val() ==='grounds-guys'){
                guyPreFilledData(nextId);
            }else if($("#conceptCode").val() === "FSP"){
                updateProgressBar()
                fspPreFilledData(nextId);
            }
            else if($("#conceptCode").val() === "MRR"){
                removePreFilledData();
                commonProgressBar();
            }else if($("#conceptCode").val() === "ASV"){
                asvPreFilledData(nextId);
            }else{
                 $('.tab-pane.fieldset').removeClass('active');
                 $('[href="#' + nextId + '"]').tab('show');
                 $('#'+nextId).addClass('active');
                 if (nextId == "step1" || nextId == "step2") {
                        $('.scheduler-modal .modal-content').addClass('banner-wizard');
                        if($("#brandName").val() == "mr-appliance"){
                            apiCall.error = false;
                            erasePreFilledDataOnClose();
                            MraProgressBar();
                            mrApplianceBrandList((data:any,status:any)=>{
                                var mrBrandList:any = [];
                                $.each(data, function (index:any, element:any){
                                    mrBrandList.push('<option id="' + element.makeId + '" value="' + element.makeId + '" data-makeCode="' + element.makeCode + '">'+element.makeName+'</option>');
                                });
                                $('#warrantyDetails #referenceDetails').empty();
                                $('#warrantyDetails #referenceDetails').append('<option value="">- select one</option>')
                                $('#warrantyDetails #referenceDetails').append(mrBrandList);
                            });
                            if(window.objOSGlobal.requestBodyValues.IsLeadOnly){
                                $('a[href="#step3"]').parent().addClass("d-none");
                                $('a[href="#step5"]').parent().removeClass("d-none")
                            }else{
                                $('a[href="#step3"]').parent().removeClass("d-none");
                                $('a[href="#step5"]').parent().addClass("d-none");
                            }
                        }
                        if($("#brandName").val() == "portland-glass"){
                            $('.scheduler-modal .modal-content').removeClass('banner-wizard');
                            $('.progress, .progressbar').hide();
                            plgPreFilledData();
                        }
                        if($("#brandName").val() == "molly-maid"){
                            commercialFlow();
                        }
                    }else if(nextId == "step3"){
                        $('.scheduler-modal .modal-content').removeClass('banner-wizard');
                        if($("#brandName").val() == "molly-maid"){
                            commercialFlow();
                            if(!window.objOSGlobal.requestBodyValues.IsLeadOnly){
                                refreshFSPCalendar(new Date().getFullYear(), new Date().getMonth() + 1);
                                $('[href="#' + nextId + '"]').tab('show');
                            }else{
                                $('.nav-wizard.progressbar').addClass('hide-schedule');
                                $('.progress-bar').css({ width:'3.2%' });
                                $('.about-home-screen').addClass('active');
                            }
                        }
                    }else{
                        $('.scheduler-modal .modal-content').removeClass('banner-wizard');
                    }
                }
                if($("#brandName").val() ==='mr-handyman' || $("#brandName").val() === "my-handyman"){
                    stopLoader()
                }else {
                    window.objOSGlobal.requestBodyValues.IsLeadOnly ? stopLoader() : stopLoaderWithDelay();
                }
                let brandCode =$("#conceptCode").val().toUpperCase();
                if(gtmEventBrandWise(brandCode)){
                    let leadObj = {
                        event:'custEv_LocalDataLayer',
                        locationID: window.objOSGlobal.requestBodyValues.WebLocationId,
                        locationDBA:window.objOSGlobal.paramsUI.doingBusinessAs,
                        locationZipcode:window.objOSGlobal.requestBodyValues.PostalCode,
                        licenseNumber:window.objOSGlobal.requestBodyValues.licenseNumber
                    }
                    gtmCustomEventLeadFlow(leadObj);
                }
                getLocalDynamicMenu();
            });
        //   }
        //   else{
        //     stopLoader();
        //     $(".tab-pane.fieldset").removeClass("active");
        //     $('.no-service-screen .head-title').html(`We're sorry. We don't currently provide service to <span class="noservice-zipcode">${window.objOSGlobal.requestBodyValues.PostalCode}</span>`);
        //     $(".no-service-screen").addClass("active");
        //     }
        // },function(data:any, status:any, error:any){
        //     stopLoader();
        //     $(".tab-pane.fieldset").removeClass("active");
        //     $('.no-service-screen .head-title').html(`We're sorry. We don't currently provide service to <span class="noservice-zipcode">${postal}</span>`);
        //     $(".no-service-screen").addClass("active");
        // });
},'');
}


// function mrApplianceList(){
//     var conceptid = 8;
//     $.ajax({
//         url: apiConfig.serviceTypeProblemAggregates + conceptid + '/service-type-problem-aggregates/search?&apikey=' + window.DEFINE_VALUE.JS_API_KEY,
//         dataType: 'json',
//         type: 'GET',
//         success: function (data:any){
//             var MRAproblemlist = data;
//             mrpappliaceProblemListAppend(MRAproblemlist);
//         }, error: function (e:any){
//             console.log(e.message);
//         }
//     });
// }

function mrpappliaceProblemListAppend(MRAproblemlist:any){
    var applianceList:any = [];
    var rows = [];
    var rowsWrap:any = [];
    $.each(MRAproblemlist, function (index:any, result:any){
        applianceList.push('<div class="appliance-col"><div class="custom-checkbox"><input class="prolemsCheckBox" value="'+result.serviceTypeProblemName+'" id="appliance'+index+'" type="checkbox"> <label for="appliance'+index+'">'+result.serviceTypeProblemName+'</label> </div> </div>');
    });
    for(let i=0;i<applianceList.length/4;i++){
        var startingIndex = i*4;
        rows.push(applianceList.slice(startingIndex,(startingIndex+4)));
    }
    for(let i=0;i<rows.length;i++){
    rowsWrap.push(mrapplianceGenerateProblemListRow(rows[i]))
    }
    $('.appliance-problem-list-wrap').empty();
    $('.appliance-problem-list-wrap').append(rowsWrap);
}
function mrapplianceGenerateProblemListRow(listArray:any){
	return '<div class="appliance-row">'+listArray.join('')+'</div>'
}

$('.mly-service-contact-form-screen input[name="inputZipCode"]').keyup(function (this: any){
    var value: any = $(this).val();
    var regex = new RegExp(/^([0-9]{5}|[A-Za-z][0-9][A-Za-z] ?[0-9][A-Za-z][0-9])$/);
    if (regex.test(value)){
        window.objOSGlobal.requestBodyValues.PostalCode = value;
        if (localStorage.getItem('zipcode') != value){
            sessionStorage.setItem('changeZip', value);
        }else{
            sessionStorage.removeItem('changeZip');
        }
    }
});