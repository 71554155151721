import { $ } from "../../third-party-imports";
import { checkStringEquals } from "../../util";
export function fspFormValidation()
{
    $.validator.setDefaults({
        errorElement: "span",
        errorPlacement: function (error: any, element: any)
        {
            error.addClass("error-msg help-block invalid-feedback");
            //If condition only for fsp service details page as it has radio buttons with images
            if(checkStringEquals($('#conceptCode')?.val(),'fsp') && ($('.tab-pane.fieldset.service-details-screen').hasClass('active'))){
                // Find the closest parent with the class 'preferServiceWrap'
                const errorParent = element.closest('.preferServiceWrap');
                if (errorParent.length) {
                    error.insertBefore(errorParent);
                    return;
                }
            }
            error.insertAfter(element);
        },
        highlight: function (element: any, errorClass: any, validClass: any)
        {
            $(element)
                .parents(".form-group")
                .addClass("has-error")
                .removeClass("has-success");
        },
        unhighlight: function (element: any, errorClass: any, validClass: any)
        {
            $(element)
                .parents(".form-group")
                .addClass("has-success")
                .removeClass("has-error");
        },
    });

    $("#contactusformfsp").validate({
        onkeyup: function (this: any, element: any)
        {
            if ($(element).val() != "" || $(element).val() == "")
            {
                $(element).valid();
            }
        },
        rules: {
            inputFirstName: {
                required: true,
            },
            inputLastName: {
                required: true,
            },
            email: {
                required: true,
                email: true,
            },
            inputPhnNumber: {
                required: true,
                minlength: 14,
                maxlength: 14,
            },
            inputAddress: {
                required: true,
            },
            inputcity: {
                required: true,
                cityValidate:true,
            },
            inputstate: {
                required: true,
            },
            inputZipCode: {
                required: true,
                addressZipValidate: true
            },
        },
        messages: {
            inputPhnNumber: "Please enter a valid phone number",
        },
    });

    $("#serviceDetails").validate({
        onkeyup: function (this: any, element: any)
        {
            if ($(element).val() != "" || $(element).val() == "")
            {
                $(element).valid();
            }
        },
        rules: {
            sacInputServiceTypeCheckbox: {
                required: true
            },
            sacInputServiceCheckbox: {
                required: true,
            },
        }
    });
}