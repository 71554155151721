import { detectMob } from "../util/detect-mob"

export function innerServiceList(root: any) {
    // let style:any;
    // i === 0 ? style = "block" : style = "none"
    let linkTarget = root.openNewTab ? "target='_blank'" : "";
    let mob = detectMob();
    if(!mob){
    return `<div class="js-mega-menu-cont" id="menu${root.id}" style="display: none;">
    <div class="row">
       <div class="col-lg-2">
          <div class="sub-menu-left">
             <span>
                <a href="${root.link}" ${linkTarget}>${root.name}</a>
             </span>
             <ul class="inner-menu-list col-list">
             </ul>
          </div>
       </div>
       <div class="col-lg-10">
       </div>
    </div>
 </div>`
    } 
    // else {
    //     return `<div class="js-mega-menu-cont2" id="menu${root.id}">
    //     <!-- Breadcrumb start -->
    //     <nav class="desktop-item" aria-label="breadcrumb">
    //        <ul>
    //           <li>
    //              <a href="${root.link}" class="js-backMenu-link">${root.name}</a>
    //           </li>
    //           <li class="active" aria-current="page">
    //              <a class="nav-title" href="#">Home Services</a>
    //           </li>
    //        </ul>
    //     </nav>
    //     <!-- Breadcrumb end -->
    //     <div class="inner-menu-row show move_close"></div>
    //  </div>`
    // }
}