import { $ } from "../third-party-imports";
import { initGalleryData } from "../util";
import { detectMob } from "../util/detect-mob";

export function onloadAddScript() {
    let slick = document.getElementById('slick-script');
    let hammer = document.getElementById('hammer-script');
    if(!slick){
        slickScript(); 
    }
    if(!hammer && detectMob()){
        hammerScript();
    }
}

function domCheckforSlick(){
    const identifiers = ['.tiles-info-slider','.carousel-container','.result-gallery .slider', '.slick-prev', '.slick-next', '#jsImageGallery', '.result-gallery', '#photo-gallery'];
    return identifiers.some((id)=> $(id).length>0);
}

function slickScript(){
    if(!domCheckforSlick()){
        return;
    }
    let slickScript = document.createElement('script');
    slickScript.setAttribute('type', 'text/javascript');
    slickScript.id = "slick-script";
    slickScript.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.js');
    slickScript.async = true;
    slickScript.addEventListener("load", function(){
        initSlider();
        initGalleryData();
    });
    document.body.appendChild(slickScript);
}
function hammerScript(){
    let hammerScript = document.createElement('script');
    hammerScript.setAttribute('type', 'text/javascript');
    hammerScript.id = "hammer-script";
    hammerScript.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/hammer.js/2.0.8/hammer.min.js');
    hammerScript.async = true;
    document.body.appendChild(hammerScript);
}

$(document).ready(function ()
{
    onloadAddScript();
});

function initSlider()
{
    let breakpointMobile = 767,
        breakpointDesktop = 768,
        isChanging = false,
        isFiltered = false;

    // Define the init handler before the initialization
    $('.tiles-info-slider').on('init breakpoint', function (event: any, slick: any)
    {
        if (!isChanging)
        {
            // Prevent infinite loop
            isChanging = true;
            // The highest breakpoint is null
            if (slick.activeBreakpoint && slick.activeBreakpoint <= breakpointMobile)
            {
                if (!isFiltered)
                {
                    slick.slickFilter(':not(.desktop-only)');
                    isFiltered = true;
                }
            } else if (slick.activeBreakpoint && slick.activeBreakpoint >= breakpointDesktop)
            {
                if (!isFiltered)
                {
                    slick.slickFilter(':not(.mobile-only)');
                    isFiltered = true;
                }
            } else
            {
                if (isFiltered)
                {
                    slick.slickUnfilter();
                    isFiltered = false;
                }
            }
            isChanging = false;
        }
    }).slick({
        slidesToShow: 3,
        infinite: true,
        arrows: true,
        dots: true,
        adaptiveHeight: true,
        variableWidth: true,
        centerMode: true,
        responsive: [
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1,
                    centerMode: false,
                    variableWidth: false,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    centerMode: false,
                    variableWidth: false,
                }
            },
            {
                breakpoint: 900,

            },
            { breakpoint: 1200 },
            { breakpoint: 1400 },
            { breakpoint: 1800 },
            { breakpoint: 3800 }
        ]
    });
    $('.carousel-container').slick({
        centerMode: true,
        centerPadding: '60px',
        slidesToShow: 3,
        variableWidth: true,
        arrows: false,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              arrows: false,
              centerMode: true,
              centerPadding: '40px',
              slidesToShow: 3,
            }
          },
          {
            breakpoint: 480,
            settings: {
              arrows: false,
              centerMode: true,
              centerPadding: '40px',
              slidesToShow: 1
            }
          }
        ]
    });

    $('.slick-prev').on('click', function() {
    $('.carousel-container').slick('slickPrev');
    });
    
    $('.slick-next').on('click', function() {
    $('.carousel-container').slick('slickNext');
    });
      //slider start
}