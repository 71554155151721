import {$} from "../third-party-imports";
import {redirectZipCode} from '../util/redirectZipCode';
import {getServiceTypeString} from '../services/getServiceTypeString';
import {servicePage} from '../services/servicePage';
import { categoryPage } from "../util/categoryPage";
import {servicesListDisplayModal} from "../util/servicesListDisplayModal"
import {fullAddressRoundRobinApi} from '../util/fullAddressRoundRobinApi';
import {getFullAttributeApi} from "../util/getFullAttributeApi";
import {getDisplayServicesList} from '../services/getDisplayServicesList';
import {getStateFun, stopLoader } from "../util";
import {FspSchedulerFlow} from "../util/FspSchedulerFlow";
import { getBrandJsonValue, servieModal } from "../util/get-brands-json";
import { getLocalDynamicMenu } from "../util/get-service-list";
export function serviceEntryBtn(){
        var postal:any = $('#locationSearch').val().toUpperCase() || $('.banner-sec input[name="cta-ser-search"]').val().toUpperCase();
        postal = postal.trim();
        let conceptCode:any  = $("#conceptCode").val();
        getBrandJsonValue();
        if(!redirectZipCode(postal)){
            return;
        }
        let domainName = location.hostname.toLowerCase();        
        let domainType = window.DEFINE_VALUE.Domain_Type;
        if (domainType == "digital"){
            var isWebSite = domainName.indexOf("-ca-") === -1 && domainName.indexOf(".ca") === -1 && domainName.indexOf("-ca.") == -1 ? "us" : "ca";
            if (isWebSite == 'ca'){
                    postal = postal.indexOf(" ") == -1 ? postal.replace(/^(.{3})(.*)$/, "$1 $2") : postal;
            }
        }
        else{
            var urlLink = window.location.href.split('/');
            var isWebSite = urlLink[3] === "ca" ? "ca" : "us";
            if (isWebSite == 'ca'){
                postal = postal.indexOf(" ") == -1 ? postal.replace(/^(.{3})(.*)$/, "$1 $2") : postal;
            }
        }
        $('.scheduler-modal .modal-content').addClass('banner-wizard');
        window.objOSGlobal.requestBodyValues.modifier = 'Residential';
        window.objOSGlobal.requestBodyValues.serviceType = '4';
        window.objOSGlobal.requestBodyValues.PostalCode= postal;
        localStorage.setItem('zipcode',postal);
        if(servieModal.enable) {
            sessionStorage.removeItem('servicePageClick');
        }
        var servicePageClick = sessionStorage.getItem('servicePageClick');
        $('#service-search-row').remove();
        if(conceptCode =='MRA'){
            let statesVal = $('.mra-contact-information-screen select[name="inputstate"]').val();
            $(".scheduler-modal .modal-dialog .modal-header.modal-header-two").removeClass('d-none');
            if(statesVal !="" || statesVal !=null){
                getStateFun();
            }
        }else{
                getStateFun();
        }
        if(servicePageClick){
            $('.zip-entry-screen').removeClass('active');
            var pageType = $('#pageType').val();
            if(pageType =='servicePage'){
                let serCat:any = true;
                sessionStorage.setItem('serCatBack',serCat);
                var serviceModifier = $("#serviceModifier").val();
                serviceModifier = serviceModifier ? serviceModifier : "Residential";
                window.objOSGlobal.requestBodyValues.modifier = serviceModifier;
                serviceModifier = getServiceTypeString(serviceModifier);
                fullAddressRoundRobinApi(postal,false,()=>{
                    getDisplayServicesList(postal,serviceModifier,function(result:any){
                    stopLoader();
                    if(result.length!=0){
                        window.objOSGlobal.requestBodyValues.FranchiseId = result[0].franchiseId+'';
                        getFullAttributeApi(window.objOSGlobal.requestBodyValues.WebLocationId,()=>{
                        servicePage();
                        getLocalDynamicMenu();
                        });
                      }else{
                        $(".tab-pane.fieldset").removeClass("active");
                        $('.no-service-screen .head-title').html(`We're sorry. We don't currently provide service to <span class="noservice-zipcode">${window.objOSGlobal.requestBodyValues.PostalCode}</span>`);
                        $(".no-service-screen").addClass("active");
                        }
                    },'')
                },
              function(data:any, status:any, error:any){
                $(".tab-pane.fieldset").removeClass("active");
                $('.no-service-screen .head-title').html(`We're sorry. We don't currently provide service to <span class="noservice-zipcode">${window.objOSGlobal.requestBodyValues.PostalCode}</span>`);
                $(".no-service-screen").addClass("active");
              });
            }
            else if(pageType =='categoryPage'){
                categoryPage();
                let serCatBtn:any = true
                sessionStorage.setItem('serCatBtnBack',serCatBtn);
            }else{
                if(conceptCode.toUpperCase() =='FSP' || servieModal.enable) {
                    FspSchedulerFlow(postal);
                }
                else if(pageType){
                    servicesListDisplayModal(postal);
                }
            }
          sessionStorage.removeItem('servicePageClick');
              let cCode = $("#conceptCode").val()
              if(cCode === 'MRA' || cCode === 'PLG' ){
              $('.scheduler-modal .modal-content').addClass('banner-wizard');
              servicesListDisplayModal(postal);
              sessionStorage.setItem('ServiceModifierValue', window.objOSGlobal.requestBodyValues.modifier);
             }
        }else if(conceptCode.toUpperCase() =='FSP' || servieModal.enable){
            FspSchedulerFlow(postal);
        }
        else{
            $('.scheduler-modal .modal-content').addClass('banner-wizard');
            servicesListDisplayModal(postal);
            sessionStorage.setItem('ServiceModifierValue', window.objOSGlobal.requestBodyValues.modifier);
        }
}