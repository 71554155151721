import { attachSearchEvent } from "./attachSearchEvent";
import { getCurrentContext } from "./getCurrentContext";
import { initPagination } from "./initPagination";

window.addEventListener("load", function() {
    // Check whether g2Data is available
    if (window.g2Data) {
        const totalCount = window.g2Data.response.numFound;
        
        initPagination(totalCount);
    
        attachSearchEvent();
		
		getCurrentContext(); // This is to get the current page URL which can be used on blog article page return button
    };
});


 