import { getCachedAPIResponse } from "../apiCaching/apiWrapper";
import { apiConfig } from "../config";
import { apiCaching } from "../config/cachingConfig";
import {$} from "../third-party-imports";
import { startLoader, stopLoader } from "./";
import { usDcaZOptInNoEmail } from "./cro-short-form";
let pageReviewNumber = 1;
let pageReviewSize =20; 
let brandReviewUrl:string;
let starRating = 5;

declare global {
    interface Window {
      brandData: any;
    }
  }

window.addEventListener("load", function(){
    initReviewsPage();
});

function initReviewsPage(){
    if($('#review').hasClass('review-section') && $('.reviews-pagination-section').is(':visible')){
        $(document).on('click', '.reviews-pagination-section .pagination-item', function(event:any) {
            event.preventDefault();
             // Exclude "Back" and "Next" buttons
            if ($(this).is('#pagination-next, #pagination-back')) {
                return;
            }
            pageReviewNumber = parseInt(event.target.id.split('-')[1]);
            if(!pageReviewNumber) return;
            startLoader();
            updateReviewUrl(); 
            $("html, body").animate({ scrollTop: 0 }, "slow");
        });
    
        $(document).on('click', '.reviews-pagination-section #pagination-next', function(event:any) {
            event.preventDefault();
            pageReviewNumber++;
            startLoader();
            updateReviewUrl();
            $("html, body").animate({ scrollTop: 0 }, "slow");
        });
    
        $(document).on('click', '.reviews-pagination-section  #pagination-back', function(event:any) {
            event.preventDefault();
            pageReviewNumber = Math.max(1, pageReviewNumber - 1);
            startLoader();
            updateReviewUrl();
            $(".review-section").animate({ scrollTop: 0 }, "slow");
        });
    }
    // REVIEWS FILTER STARTS HERE
    const $popover = $('#popover');
    const $popoverBtn = $('#popoverBtn, #starsDisplay');
    const $downArrow = $('#downArrow');
    const $upArrow = $('#upArrow');
    const $resetLink = $('#resetLink');
    const $ratingForm = $('#ratingForm');
    const $ratingText = $('#ratingText');
    const $ratingsInfo = $('#ratingsInfo');
    const $starsDisplay = $('#starsDisplay');
    let defaultRating = $ratingText.text()

    // Safe check for popover button click event
    if ($popoverBtn.length) {
        $popoverBtn.on('click', function() {
            const rect = this.getBoundingClientRect();
            $popover.css({
                top: `${rect.bottom + window.scrollY}px`,
                left: `${rect.left + window.scrollX + rect.width / 2 - $popover.outerWidth() / 2}px`
            }).toggleClass('hidden');
            $downArrow.toggleClass('hidden');
            $upArrow.toggleClass('hidden');
        });
    }

    // Safe check for rating form change event
    if ($ratingForm.length) {
        $ratingForm.on('change', 'input[name="rating"]', function() {
            const selectedRating = parseInt($(this).val(), 10);
            $popover.addClass('hidden');
            $downArrow.removeClass('hidden');
            $upArrow.addClass('hidden');
            $ratingText.text('Showing:')
                       .removeClass('ratings-text')
                       .addClass('showing-text');
            $resetLink.removeClass('hidden')
                      .addClass('reset-text');
            $ratingsInfo.removeClass('ratings-info')
                        .addClass('hidden');
            updateStarsDisplay(selectedRating);
        });
    }

    // Reset link click event with safe check
    if ($resetLink.length) {
        $resetLink.on('click', function() {
            starRating = 5;
            $resetLink.addClass('hidden');
            $popoverBtn.removeClass('hidden');
            $downArrow.removeClass('hidden');
            $upArrow.addClass('hidden');
            updateStarsDisplay(starRating);

            // Uncheck all radio buttons
            $ratingForm.find('input[name="rating"]').prop('checked', false);
            $ratingsInfo.removeClass('hidden')
                        .addClass('ratings-info');
            $('#ratingText').text(defaultRating)
            .removeClass('showing-text')
            .addClass('ratings-text');
        });
    }

    // Function to update the stars display
    function updateStarsDisplay(rating: number) {
        if (!$starsDisplay.length) return;  // Safe check if $starsDisplay is missing
    
        $starsDisplay.empty();
        const stars = Array.from({ length: rating }, () =>
            $('<span>').addClass('star full mr-1').html('&#9733;')
        );
        $starsDisplay.append(stars);
    
        starRating = rating;  // Simplified conditional assignment
        updateReviewUrl();
    }

    // Document click event to close popover if clicked outside
    $(document).on('click', function(event: { target: any; }) {
        if(event.target.id === 'service-reminder-modal'){
            usDcaZOptInNoEmail();
        }
        if (!$popover.is(event.target) && !$popoverBtn.is(event.target) &&
            $popover.has(event.target).length === 0 && $popoverBtn.has(event.target).length === 0) {
            if (!$popover.hasClass('hidden')) {
                $popover.addClass('hidden');
                $downArrow.removeClass('hidden');
                $upArrow.addClass('hidden');
            }
        }
    });
}


async function updateReviewUrl(){
    const concepId = $('#conceptId').val();
    const webLocationId = $("#weblocationId").val();
    let countryId = ($("#countryCode").val() === 'us') ? 1 : 2 ;
    const result = window.brandData;
    // Example usage - Call this function to get the selected rating
    if (result)
    {
        let franchiseWebLocationIdParam = webLocationId ? `&FranchiseWebLocationId=${webLocationId}` : '';
        brandReviewUrl = `${apiConfig.BroadlyReviewAPI}${concepId}${franchiseWebLocationIdParam}&PageNumber=${pageReviewNumber}&PageSize=${pageReviewSize}&CountryId=${countryId}&StarRating=${starRating}`;
        broadlyReviewApiCall(brandReviewUrl);
    } else {
        $('.review-section .reviews-pagination-section').addClass('d-none');
    }
}

async function broadlyReviewApiCall(brandUrl:any){
    $('.review-section .review-container').remove();
    try
    {
        let data = await getCachedAPIResponse(brandUrl, apiCaching.TestimonyAndReview);
        // If no data, hide review section & show no review message
        if(!data?.length){
            showNoReviewPage();
            return;
        }
        hideNoReviewPage();
        const reviewContainer:string[] = [];
        const paginationComponent = getPaginationComponent(data?.length ? Number(data[0]?.grandTotal || data.length) : 0);
        data.forEach((element:any) => {
            reviewContainer.push(generateReviewContainer(element));
        });
        stopLoader();
        $('.review-section .reviews-pagination-section').removeClass('d-none');
        $('.review-section').show();
        $('.review-section .reviews-pagination-section nav[aria-label="pagination"]').hide();
        $('.total-review strong').text(data.length);
        $('.review-section .reviews-pagination-section').before(reviewContainer);
        $('.reviews-pagination-section .pagination-wrapper').html(paginationComponent)
    } catch (error: any)
    {
        stopLoader();
        showNoReviewPage();
        console.error(`Error in fetching Testimonial api response Error message: ${error}`);
    }
        
}
function getPaginationComponent(count:any) {
    if (!count) return '';

    const totalPages = Math.ceil(count / pageReviewSize);
    let numPagesToShow = $(window).width() <= 768 ? 3 : 5; // Number of pages to display before and after ellipses
    $(window).on("resize", function () {
        numPagesToShow = $(window).width() <= 768 ? 3 : 5;
    });
    const currentPage = pageReviewNumber;
    let arrPageNumbers = '';

    function generatePageNumbers(start:any, end:any) {
        for (let i = start; i <= end; i++) {
            if (i === currentPage) {
                arrPageNumbers += `<li><a href="#"  class="pagination-item current" aria-current="page"><span class="sr-only">page</span>${i}</a></li>`;
            } else {
                arrPageNumbers += `<li><a class="pagination-item" id="${'page-'+i}" href="#"><span class="sr-only">page</span>${i}</a></li>`;
            }
        }
    }

    if (totalPages <= numPagesToShow) {
        // Display all pages if total pages are less than or equal to numPagesToShow
        generatePageNumbers(1, totalPages);
    } else {
        let start = 1, end = numPagesToShow;
        let middlePage = Math.floor(numPagesToShow / 2) + 1; // Selected Middle page number
        if (currentPage <= middlePage) {
            start = 1;
            end = numPagesToShow;
        } else if (currentPage >= totalPages - middlePage) {
            start = totalPages - numPagesToShow + 1;
            end = totalPages;
        } else {
            start = currentPage - Math.floor(numPagesToShow / 2);
            end = currentPage + Math.floor(numPagesToShow / 2);
        }
        generatePageNumbers(start, end);
        if(end !=  totalPages ){
            arrPageNumbers += (end != totalPages - 2)?` <li class="ellipsis">[...]</li> `:'';
            generatePageNumbers((end >= totalPages - 2) ? end+1 : totalPages - 2, totalPages);
        }
    }

    return `<div class="row">
            <div class="col-md-6">
                <nav aria-label="pagination">
                    <ul class="pagination text-sm">
                        ${pageReviewNumber === 1 ?
                           '<li><a href="#" id="pagination-back" class="pagination-item disabled first" aria-disabled="true" aria-label="Previous page">Back</a></li>' 
                         : `<li><a href="#" id="pagination-back" class="pagination-item  first" aria-disabled="false" aria-label="Previous page">Back</a></li>`}
                        ${arrPageNumbers}
                        ${pageReviewNumber != totalPages ?
                                `<li><a  id="pagination-next" class="pagination-item last" href="#" aria-disabled="false" aria-label="Next page">Next</a></li>`
                              : '<li><a  id="pagination-next" class="pagination-item last disabled"   href="#" aria-disabled="true" aria-label="Next page">Next</a></li>' }
                    </ul>
                </nav>
            </div>
            <div class="col-md-6 body-text-sm dark-grey-primary-clr total-review">
                <p>Total number of ${starRating} star reviews  :<strong class="secondary-theme-clr text-sm">${count}</strong></p>
            </div>
        </div>`;
}

function generateStars(rating:number):string{
    const fullStar = '<span class="star full">★ </span>';
    const emptyStar = '<span class="star empty">☆ </span>';
    const numberOfFullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 !== 0;
    const numberOfEmptyStars = hasHalfStar ? 5 - numberOfFullStars - 1 : 5 - numberOfFullStars;
    return fullStar.repeat(numberOfFullStars) + (hasHalfStar ? '<span class="star half">★ </span>' : '') + emptyStar.repeat(numberOfEmptyStars);
}

function generateReviewContainer(element: any) {
    const reviewBody = element.reviewBody ?? '';
    const reviewPhotoUrl = element.reviewPhotoUrl ?? '/us/en-us/brand/_assets/images/user-icon-m.webp';
    const reviewSource = element.reviewSourceType 
        ? `<strong class="text-sm review-source">via ${element.reviewSourceType}</strong>` 
        : '';
    const reviewResponse = element.reviewResponseBody 
        ? `<div class="reviewer-info-wrap review-response mobile-left-align dark-grey-primary-clr">
               <h5 class="response-party">Response from <span class="response-party-author">${element.reviewResponseName}</span></h5>
               <p>${element.reviewResponseBody}</p>
           </div>` 
        : '';

    return `
        <div class="review-container">
            <div class="container" aria-describedby="review">
                <div class="user-avatar">
                    <img src="${reviewPhotoUrl}" 
                         class="img-fluid" 
                         width="48" 
                         height="48" 
                         alt="${element.reviewAuthorName}" 
                         fetchpriority="low" 
                         loading="lazy">
                </div>
                <div class="user-review">
                    <div class="reviewer-info-wrap">
                        ${generateStars(element.reviewStarRating)}
                        <strong class="text-lg">${element.reviewAuthorName}</strong>    
                        ${reviewSource}
                    </div>
                    <p class="dark-grey-primary-clr">${reviewBody}</p>
                    ${reviewResponse}
                </div>
            </div>
        </div>`;
}

function showNoReviewPage(){
    $('.review-section').hide();
    $('.no-review-section').show();
}

function hideNoReviewPage(){
    $('.review-section').show();
    $('.no-review-section').hide();
}





