export function bookingApiSuccessHandlerData(payload:any){
    let brandName = $('#brandName').val();
    localStorage.setItem("OSWebLocationId", window.objOSGlobal.requestBodyValues.WebLocationId);
    localStorage.setItem("OSDbaFullName", window.objOSGlobal.paramsUI.doingBusinessAs);
    let addressObj = payload.Address2 != "" ? payload.Address + ' ' + payload.Address2 : payload.Address;
    let fulladdress = addressObj + '<br/>' + payload.City + ', ' + payload.State + ' ' + payload.PostalCode;
    let plgVehicle = payload.Year+ ' '+ payload.Make + ' ' + payload.Model;
    sessionStorage.setItem('plg-name', payload.FirstName+' '+ payload.LastName);
    sessionStorage.setItem('plg-email', payload.Email);
    sessionStorage.setItem('plg-phone', payload.Phone);
    sessionStorage.setItem('plg-address', fulladdress);
    sessionStorage.setItem('plg-vehicle', plgVehicle);
    sessionStorage.setItem('plg-style', payload.BodyStyle);
    sessionStorage.setItem('plg-vin', payload.VIN);
    sessionStorage.setItem('plg-license', payload.LicensePlateNumber);
    if(window.objOSGlobal.requestBodyValues.WindshieldRepair == 'Yes'){
        sessionStorage.setItem('plg-glass', 'Windshield Repair (Damage is smaller than a quarter and not in your field of vision)');
    }else{
        sessionStorage.setItem('plg-glass', 'Windshield Replacement');
    }
    sessionStorage.setItem('plg-replace', payload.ServiceRequested.toString());
    let typeOfServiecPlg = sessionStorage.getItem('typeOfServiecPlg');
    if(typeOfServiecPlg =="dropoff2"){
        let addressMob = sessionStorage.getItem('addressMob');
        let apartmentMob = sessionStorage.getItem('apartmentMob');
        let cityMob = sessionStorage.getItem('cityMob');
        let stateMob = sessionStorage.getItem('stateMob');
        let postalMob = sessionStorage.getItem('postalMob');
        let addressMobObj = apartmentMob != "" ? addressMob + ' ' + apartmentMob : addressMob;
        let fulladdressMob = addressMobObj + '<br/>' + cityMob + ', ' + stateMob + ' ' + postalMob;
        sessionStorage.setItem('plg-service', fulladdressMob);
    }
    if(payload.FormOfPayment == "Insurance Claim"){
        let paymentType = 'Insurance with: <br/>'+payload.InsuranceCompany+'<br/>Policy Number: '+payload.PolicyNumber+'<br/>Deductible: $'+payload.DeductibleAmount;
        sessionStorage.setItem('plg-payment', paymentType);
    }else{
        sessionStorage.setItem('plg-payment', payload.FormOfPayment);
    }
    let trailingSlash = localStorage.getItem("isTrailingSlash") == "true" ? "/" : "";
    let domainName = window.location.origin.toLowerCase();
    var websiteurl = $('#weblocationId').length ? domainName + "/" + $('#dbaName').val() + "/" : domainName;
    if(brandName == "protect-painters"){
       websiteurl = websiteurl + "/" + brandName;
    }
    if(websiteurl.includes('confirmation')){
        window.location.replace(websiteurl+ trailingSlash);
    }else{
        window.location.replace(websiteurl + "/" + "confirmation" + trailingSlash);
    }
}