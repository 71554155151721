import { LeadConfirmation } from "../../services/LeadConfirmation";
import { $ } from "../../third-party-imports";
import { addDataToNotes, checkZipLocalSite, GetReferenceDetails, refreshFSPCalendar, showOfferAttachedText } from "../../util";
import { changeZipCodeInContactScreenFlow } from "../common-os/change-zip-code-contact";

export function fsp_OptOsFlow()
{   
    GetReferenceDetails();
    changeZipCodeInContactScreenFlow();

    $(".calendar-row").on("click", ".calendar-btn", function (this: any){
        $(".dt-error-calender").remove();
    });   
    $('input[name="sacInputServiceTypeCheckbox"]').on("change", function () {
        const selectedValue = $('input[name="sacInputServiceTypeCheckbox"]:checked').val();
        if (selectedValue) {
            window.objOSGlobal.requestBodyValues.serviceProperty = selectedValue;
        }
    }); 
    $('input[name="sacInputServiceCheckbox"]').on("change", function () {
        const selectedValue = $('input[name="sacInputServiceCheckbox"]:checked').val();
        if (selectedValue) {
            window.objOSGlobal.requestBodyValues.serviceType = selectedValue;
        }
    });

    if ($("#conceptCode").val() == "FSP") {
        $(".js-next-btn").click(async function (this: any) {
            let nextId = $(this).parents(".tab-pane").next().attr("id");
            if(!window.objOSGlobal.requestBodyValues.IsLeadOnly && nextId == "step4"){
                refreshFSPCalendar(new Date().getFullYear(), new Date().getMonth() + 1);
            }
            if (nextId == "step2") {
                    let postalCode = localStorage.getItem("zipcode");
                    $('#contactusformfsp input[name="inputZipCode"]').val(
                        postalCode
                    );
            } 
            if (nextId == "step3") {
                //Show Service Details Page
                if($('#referenceDetails').val()){
                    window.objOSGlobal.requestBodyValues.LeadSourceId=$('#referenceDetails').val();
                }
                if ($("#contactusformfsp").valid()) {
                    const changeZip = sessionStorage.getItem("changeZip");
                    if (changeZip) {
                        checkZipLocalSite(nextId);
                    }
                    fspContactInformation();
                    loadFSPServiceDetailsPage();
                } else{
                    $('.contact-information-screen').addClass('active');
                }
                showOfferAttachedText();
            } 
            
            else if (nextId == "step4") {
                if ($("#serviceDetails").valid()) {
                    if(window.objOSGlobal.requestBodyValues.IsLeadOnly){
                        //Submit the form
                        // fspServiceDetails();
                        addDataToNotes('ID: 5ubm17 -- Partial Submits');
                        addDataToNotes('ID: 5ubm17 -- Partial Submits','Comments');
                        LeadConfirmation();
                    }
                    else{
                        //Show Calendar Page
                        loadCalendarPage();
                    }
                }
                
            } 
        
    });

     //Previous button click code
        $(".js-previous-btn, .sac-calender-info-form .scheduler-btn.secondary-btn.sac-back").click(function (this: any)
        {
            if ($('#conceptCode').val() == "FSP")
            {
                let prevId = $(this).parents('.tab-pane').prev().attr("id");
                if (prevId == "step4")
                    {
                        loadCalendarPage();
                    }
                if (prevId == "step3")
                {
                    loadFSPServiceDetailsPage();
                }
                if (prevId =="step2") {
                    loadFSPContactInfoPage();
                }
                else if (prevId == "step1")
                {
                    //Show location search screen
                    loadLocationSearchPage();
                } else
                {
                    $('[href="#' + prevId + '"]').tab('show');
                }
            }
        });
        $(".sac-calender-info-form .scheduler-btn.primary-btn.sac-next").click(async function (this: any) {
            LeadConfirmation();
        });

    }
    
    
    //Progress bar progress code
    $('a[data-toggle="tab"]').on('shown.bs.tab', function (e: any)
    {
        //update progress
        let isLead = window.objOSGlobal.requestBodyValues.IsLeadOnly;
        let checker: any = false;
        if (isLead)
        {
            checker = true;
        }
        let step = $(e.target).data('step');
        let percent: any = ((parseInt(step) / 3) * 100).toFixed(2);
        switch (step)
        {
            case 1:
                percent = 0;
                $('.progress-bar').css({ width: percent + '%' });
                break;
            case 2:
                percent = 5.2;
                $('.progress-bar').css({ width: percent + '%' });
                break;
            case 3:
                percent = checker ? 5.2 : 53;
                $('.progress-bar').css({ width: percent + '%' });
                break;
            case 4:
                percent = 100;
                $('.progress-bar').css({ width: percent + '%' });
                break;
            default:
                $(".progress").addClass("d-none");
                $(".progressbar").addClass("d-none");
                $(".modal-header-two").addClass("d-none");
                $(".modal-header-one").addClass("d-none");
                break;
        }
    });
}

export function fspContactInformation(){
    let firsName = $(".fsp-contact-info-screen input[name='inputFirstName']").val();
    let lastName = $(".fsp-contact-info-screen input[name='inputLastName']").val();
    let email = $(".fsp-contact-info-screen input[name='email']").val();
    let phoneNumber = $(".fsp-contact-info-screen input[name='inputPhnNumber']").val();
    let address = $(".fsp-contact-info-screen input[name='inputAddress']").val();
    let apartment = $(".fsp-contact-info-screen input[name='inputAddress2']").val();
    let city = $(".fsp-contact-info-screen input[name='inputcity']").val();
    let zipcode  = $(".fsp-contact-info-screen input[name='inputZipCode']").val();
    let state  = $(".fsp-contact-info-screen select[name='inputstate']").val();
    window.objOSGlobal.requestBodyValues.FirstName = firsName;
    window.objOSGlobal.requestBodyValues.LastName = lastName;
    window.objOSGlobal.requestBodyValues.Email = email.trim();
    window.objOSGlobal.requestBodyValues.Phone = phoneNumber;
    window.objOSGlobal.requestBodyValues.Address = address;
    window.objOSGlobal.requestBodyValues.Address2 = apartment;
    window.objOSGlobal.requestBodyValues.City = city;
    window.objOSGlobal.requestBodyValues.State = state;
    window.objOSGlobal.requestBodyValues.PostalCode = zipcode;
    var addressParamsVal:any = address + "&" + apartment + "&" + city + "&" + state + "&" + window.objOSGlobal.requestBodyValues.PostalCode;
    return addressParamsVal
}

function loadLocationSearchPage() {
    $('.tab-pane.fieldset').removeClass('active');
    $('.zip-entry-screen').addClass('active');
    $('.schedulerModalLabel').addClass('d-none');
    $('.schedulerModalLabel').empty();
}

export function loadFSPContactInfoPage() {
    $('.tab-pane.fieldset').removeClass('active');
    $(".contact-information-screen").addClass("active");
    $('.step-progressbar-wrap .step-progressbar li').removeClass('current');
    $('.step-progressbar-wrap .step-progressbar li').removeClass('active');
    $('.step-progressbar-wrap .step-progressbar li[data-step="sacStep2"]').addClass('current');
}

function loadFSPServiceDetailsPage() {
    $('.tab-pane.fieldset').removeClass('active');
    $(".service-details-screen").addClass("active");
    $('.step-progressbar-wrap .step-progressbar li').removeClass('current');
    $('.step-progressbar-wrap .step-progressbar li').removeClass('active');
    $('.step-progressbar-wrap .step-progressbar li[data-step="sacStep3"]').addClass('current');
    $('.step-progressbar-wrap .step-progressbar li[data-step="sacStep2"]').addClass('active');
}
function loadCalendarPage() {
    $('.tab-pane.fieldset').removeClass('active');
    $('.calendar-screen').addClass('active'); 
    $('.step-progressbar-wrap .step-progressbar li').removeClass('current');
    $('.step-progressbar-wrap .step-progressbar li').removeClass('active');
    $('.step-progressbar-wrap .step-progressbar li[data-step="sacStep2"]').addClass('active');
    $('.step-progressbar-wrap .step-progressbar li[data-step="sacStep4"]').addClass('current');
    $('.step-progressbar-wrap .step-progressbar li[data-step="sacStep3"]').addClass('active');
}

