import {setAlternateBrandData} from './setAlternateBrandData';
export function youtubeInit() 
{
    ytFunc();
    let webLocationId = $("#weblocationId").val();
    webLocationId = webLocationId ? webLocationId : localStorage.getItem('localWebLocationId');
    if(webLocationId) {
		setAlternateBrandData();
	}
}

function ytFunc(){
    const youtubeElements = Array.from(document.querySelectorAll( ".youtube" )) as HTMLElement[];
    if(youtubeElements.length>0){
        for (const youtube of youtubeElements)  
        {	const videoPoster = youtube.querySelector('.video-poster');
            const embedCode = youtube.getAttribute('data-embed');
            if(videoPoster && embedCode){
                videoPoster.addEventListener("click", () => {
                    const iframe = document.createElement("iframe");
                    iframe.setAttribute("frameborder", "0");
                    iframe.setAttribute("allowfullscreen", "");
                    iframe.setAttribute("allow", "autoplay; encrypted-media");
                    iframe.setAttribute("src", `https://www.youtube.com/embed/${embedCode}?autoplay=1&rel=0&showinfo=0`);
                    youtube.innerHTML = iframe.outerHTML;
                });
            }
            
        }
    }
}