import { detectMob } from "../util/detect-mob";
import { mobileMenuModifier } from "./mobile/mobile";
export function mrhWraplistComponent(root:any){
   let mob = detectMob();
   if(!mob) {
   return  `<ul class="dropdown-menu megamenu" aria-labelledby="menu${root.id}">
    <li class="row ml-0 mr-0">
       <div class="dropmenu new-submenu">
          <ul class="mega-menu-front js-mega-menu-front" style="" id = "dsk-menu-menu${root.id}">
             <li class="row ml-0 mr-0">
                <div class="dropmenu">
                   <div class="menu-title">
                      <span>${root.name}</span>
                   </div>
                </div>
             </li>
          </ul>
          <ul class="mega-menu-front js-mega-menu-front"></ul>
          <div class="mega-menu-links"></div>
        </div>
    </li>
  </ul>`
   }
   else {
    return `<ul class="dropdown-menu megamenu cust" aria-labelledby="menu${root.id}">
                 <li class="row ml-0 mr-0">
                    <div class="dropmenu">
                        <ul class="mega-menu-front js-mega-menu-front">
                            <li>
                                <button class="back-arrow-link js-backMenu mob-link" aria-label="Back to main menu">Back</button>
                            </li>
                            <li>
                              <span class="overview-link"><a href="${root.link}">${root.name}</a></span>
                            </li>
                        </ul>
                     ${mobileMenuModifier(root)}
                    </div>
                </li>
            </ul>`;
   }
}
