import {$} from "../third-party-imports";
import {getAddressBumperValue} from '../util/getAddressBumperValue';
export function contactInformationScreen(){
    const concepId = $("#conceptCode").val(); 
    if(concepId === "MLY"){
        if (!$("#contactusformMly").valid()) {
            return;
        }
    }
    else if(concepId === "MRA"){
        if (!$("#contactusformMra").valid()) {
            return;
        }
        $(".contact-information-screen").removeClass('active');
    }else if(concepId !== "FSP"){
        $(".contact-information-screen").removeClass('active');
    }
   
    var addresses= JSON.parse(localStorage.getItem("address")); 
    if(addresses){
        getAddressBumperValue(addresses);
    }
    const firstnameobj = $('.personal-info-form').find('input[name="inputFirstName"]').val();
    const lastnameobj = $('.personal-info-form').find('input[name="inputLastName"]').val(); 
    const emailobj = $('.personal-info-form').find('input[name="email"]').val();
    const mobileNumberobj = $('.personal-info-form').find('input[name="inputPhnNumber"]').val();
    window.objOSGlobal.requestBodyValues.FirstName = firstnameobj;
    window.objOSGlobal.requestBodyValues.LastName = lastnameobj;
    window.objOSGlobal.requestBodyValues.Email = emailobj;
    window.objOSGlobal.requestBodyValues.Phone = mobileNumberobj;
    $('.address-details-screen').addClass('active');
    if(window.objOSGlobal.requestBodyValues.PostalCode){
      $("#serviceAddress #inputZipCode").val(window.objOSGlobal.requestBodyValues.PostalCode);
    }
}